import { Grid, List, Stack, Text, TextInput } from "@mantine/core"
import React from "react"
import { AlertCircle } from "tabler-icons-react";

interface flattenedMagnet {
    startTime: string,
    endTime: string,
    clockedIn: string,
    exceptions: any,
}

const TTCPluginDisplay = ({ magnetData }: any) => {
    const flattenedData: flattenedMagnet = {
        startTime: magnetData?.pluginxmagnet?.stored_object?.startTime ?
            new Date(magnetData?.pluginxmagnet?.stored_object?.startTime).toLocaleString() : "~",
        endTime: !magnetData?.pluginxmagnet?.stored_object?.endTime ? "~" :
            new Date(magnetData?.pluginxmagnet?.stored_object?.endTime).toLocaleString(),
        clockedIn: magnetData?.pluginxmagnet?.clocked_in == true || false ? magnetData?.pluginxmagnet?.clocked_in?.toString() : 'false',
        exceptions: magnetData?.pluginxmagnet?.stored_object?.exceptions
    };

    return (
        <Stack>
            <Grid gutter="lg">
                <Grid.Col span={6}>
                    <TextInput
                        size="md"
                        disabled
                        label="Clocked In"
                        placeholder={flattenedData?.clockedIn}
                        style={{ paddingTop: '10px' }}
                    />
                    <TextInput
                        size="md"
                        label="Start Time"
                        placeholder={flattenedData?.startTime}
                        style={{ paddingTop: '10px' }}
                        disabled
                    />
                    <TextInput
                        size="md"
                        label="End Time"
                        placeholder={flattenedData?.endTime ? flattenedData?.endTime : "`"}
                        style={{ paddingTop: '10px' }}
                        disabled
                    />
                </Grid.Col>
                <Grid.Col span={6}>
                    <Text style={{ paddingTop: '10px' }} fw={600}>Worker Exceptions - {new Date().toLocaleDateString()}</Text>
                    {flattenedData?.exceptions?.length > 0 ? (
                        flattenedData?.exceptions.map((exception: any, i: number) => (
                            <List key={i} icon={<AlertCircle strokeWidth={2} color={'#FFFF00'} />}>
                                <List.Item>
                                    Code {exception?.exceptionCode?.codeValue} - {exception?.exceptionTypeCode?.codeValue} - {exception?.exceptionDescription}
                                </List.Item>
                            </List>
                        ))
                    ) : (
                        <Text>No worker exceptions found!</Text>
                    )}
                </Grid.Col>
            </Grid>
        </Stack>
    )
};

export default TTCPluginDisplay;
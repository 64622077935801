import { Button, Divider, Group, Modal, Space, Text, Title } from '@mantine/core'
import { CircleSlashIcon } from '@primer/octicons-react';
import { useState } from 'react';
import { DeviceFloppy } from 'tabler-icons-react'

export const SaveButton = ({ boolSaveOrCreate, saveFunction, createFunction, data, loading, disabled }) => {
  const [showSaveModal, setShowSaveModal] = useState(false);

  return (
    <>
      <Modal opened={showSaveModal} withCloseButton={true} onClose={() => setShowSaveModal(false)}>
        <Title align='center' order={5}>Warning!</Title>
        <Text>Editing this board will delete any preset saved in the live viewing.
          Existing magnets will remain on the board where applicable after saving changes.  Do you wish to continue?
        </Text>
        <Space h='xl' />
        <Divider />
        <Space h='xl' />
        <Group position='apart'>
          <Button
            color='red.4'
            leftIcon={(<CircleSlashIcon />)}
            onClick={() => setShowSaveModal(false)}
          >Discard</Button>
          <Button
            color='cyan'
            disabled={disabled}
            leftIcon={(<DeviceFloppy />)}
            onClick={() => {
              setShowSaveModal(false);
              saveFunction(data);
            }
            }
            loading={loading}
          >Continue
          </Button>
        </Group>
      </Modal>
      <Button
        variant='filled'
        disabled={disabled}
        onClick={() => boolSaveOrCreate ?
          //saveFunction(data)
          setShowSaveModal(true)
          :
          createFunction(data)}
        leftIcon={<DeviceFloppy />}
        loading={loading}
      ><Space w={10} />{boolSaveOrCreate ? "Save" : "Create New"}
      </Button>
    </>
  )
}

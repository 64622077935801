import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import { RoleStore } from './RoleStore';
import { Container, Title } from '@mantine/core';
import { observer } from 'mobx-react';
import useSettings from '../../../contexts/contextHooks/useSettings';
import { TableSort } from '../../Utilities/TableSort';

interface RoleListProps {
}

const RoleList = ({ }: RoleListProps) => {
    const navigate = useNavigate();

    const { fullNavbar }: { fullNavbar?: boolean } = useSettings();
    const handleEditRole = (id: number) => {
        navigate(String(id));
    }
    const handleNewRole = () => {
        navigate('new');
    }
    const tableSortProps = {
        postsPerPage: 8,
        data: RoleStore.roles.map((r) => {
            return {
                id: String(r.id),
                "Role Name": r.name
            }
        }),
        handleClickNew: handleNewRole,
        handleClickItem: handleEditRole,
    }

    useEffect(() => {
        RoleStore.getRoles();
    }, []);

    return (<>
        <Container size="md" pr={{ xl: fullNavbar ? '300px' : '' }} style={{ marginTop: '8vh' }}>
            {/* <LoadingOverlay visible={loading} /> */}
            <Title align="center" order={3}>Role List</Title>
            <TableSort
                newButtonText='Create Role'
                {...tableSortProps}
            />
        </Container>
    </>)
}

export default observer(RoleList);
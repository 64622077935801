import { useMantineTheme } from "@mantine/core";
import { createContext, useState, useEffect } from "react";
import { Coffee, Leaf, Moon, Palette, Sun } from "tabler-icons-react";

const SettingsContext = createContext({});

export const SettingsProvider = ({ children }) => {
    const theme = useMantineTheme();
    const [fullNavbar, setShowNavbar] = useState(true);

    const themes = {
        "Light theme": {
            label: 'Light theme',
            icon: <Sun />,
            title: '#ffffff',
            header: '#0B7285',
            colorScheme: 'light',
            colors: {
                'cyan-mb': ['#66D9E8', '#3BC9DB', '#22B8CF', '#15AABF', '#1098AD', '#0C8599', '#0B7285', '#00596C', '#003F52', '#002639'],
            },
            primaryColor: 'cyan-mb'
        },
        "Dark theme":
        {
            label: 'Dark theme',
            icon: <Moon />,
            title: theme.primaryColor[0],
            header: 'hsl(200, 100%, 8%)',
            colorScheme: 'dark',
            colors: {
                'cyan-mb': ['#66D9E8', '#3BC9DB', '#22B8CF', '#15AABF', '#1098AD', '#0C8599', '#0B7285', '#00596C', '#003F52', '#002639'],
                dark: ['#ffffff', '	hsl(187, 69%, 90%)', 'hsl(188, 72%, 80%)', 'hsl(200, 100%, 16%)', 'hsl(200, 100%, 13%)', 'hsl(200, 100%, 10%)', 'hsl(200, 100%, 8%)', 'hsl(200, 100%, 6%)', 'hsl(200, 100%, 3%)', 'hsl(200, 100%, 1%)']
            },
            primaryColor: 'cyan-mb'
        },
        "Java theme":
        {
            label: 'Java theme',
            icon: <Coffee />,
            title: theme.primaryColor[0],
            header: 'hsl(27, 88%, 13%)',
            colorScheme: 'dark',
            colors: {
                'java': ['#66D9E8', '#3BC9DB', '#22B8CF', '#15AABF', '#1098AD', '#0C8599', '#0B7285', '#00596C', '#003F52', '#002639'],
                dark: ['#ffffff', '	hsl(187, 69%, 90%)', 'hsl(188, 72%, 80%)', 'hsl(200, 100%, 16%)', 'hsl(200, 100%, 13%)', 'hsl(200, 100%, 10%)', 'hsl(200, 100%, 8%)', 'hsl(200, 100%, 6%)', 'hsl(200, 100%, 3%)', 'hsl(200, 100%, 1%)']
            },
            primaryColor: 'java'
        },
        "Spearmint theme": {
            label: 'Spearmint theme',
            icon: <Leaf />,
            title: theme.primaryColor[0],
            header: 'hsl(27, 88%, 13%)',
            colorScheme: 'dark',
            colors: {
                'java': ['#66D9E8', '#3BC9DB', '#22B8CF', '#15AABF', '#1098AD', '#0C8599', '#0B7285', '#00596C', '#003F52', '#002639'],
                dark: ['#ffffff', '	hsl(187, 69%, 90%)', 'hsl(188, 72%, 80%)', 'hsl(200, 100%, 16%)', 'hsl(200, 100%, 13%)', 'hsl(200, 100%, 10%)', 'hsl(200, 100%, 8%)', 'hsl(200, 100%, 6%)', 'hsl(200, 100%, 3%)', 'hsl(200, 100%, 1%)']
            },
            primaryColor: 'java'
        },
        "Pastel theme": {
            label: 'Pastel theme',
            icon: <Palette />,
            title: theme.primaryColor[0],
            header: 'hsl(27, 88%, 13%)',
            colorScheme: 'dark',
            colors: {
                'java': ['#66D9E8', '#3BC9DB', '#22B8CF', '#15AABF', '#1098AD', '#0C8599', '#0B7285', '#00596C', '#003F52', '#002639'],
                dark: ['#ffffff', '	hsl(187, 69%, 90%)', 'hsl(188, 72%, 80%)', 'hsl(200, 100%, 16%)', 'hsl(200, 100%, 13%)', 'hsl(200, 100%, 10%)', 'hsl(200, 100%, 8%)', 'hsl(200, 100%, 6%)', 'hsl(200, 100%, 3%)', 'hsl(200, 100%, 1%)']
            },
            primaryColor: 'java'
        },

    }

    const [appearanceTheme, setAppearanceTheme] = useState(
        themes[JSON.parse(window.localStorage.getItem('colorScheme'))]?.label &&
            JSON.parse(window.localStorage.getItem('colorScheme')) === themes[JSON.parse(window.localStorage.getItem('colorScheme'))].label ?
            themes[JSON.parse(window.localStorage.getItem('colorScheme'))]
            :
            themes['Light theme']
    )

    const getAppearance = () => {
        return (appearanceTheme);
    }

    const setAppearance = (themeLabel) => {
        setAppearanceTheme(themes[themeLabel]);
        return (appearanceTheme);
    }

    const toggleNavbar = () => {
        setShowNavbar(!fullNavbar);
        return (fullNavbar);
    }

    useEffect(() => {
        window.localStorage.setItem('colorScheme', JSON.stringify(appearanceTheme.label));
    }, [appearanceTheme]);

    return (
        <SettingsContext.Provider value={{ fullNavbar, toggleNavbar, appearanceTheme, setAppearanceTheme, getAppearance, setAppearance }}>
            {children}
        </SettingsContext.Provider>
    )
}

export default SettingsContext;
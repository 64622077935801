import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { getActiveTemplates } from '../../exports/fetch/TemplateFetch'
import { Tabs, Center, Title, Container, Grid, Card, Group, Pagination, Image, LoadingOverlay, useMantineTheme, Text } from '@mantine/core';
import { Settings } from 'tabler-icons-react';
import TemplateSelectedView from './TemplateSelectedView';
import logo from '../../images/mb-full-trans.png'
import { AuthStore } from '../../stores/authStore';
import AdminHeaderMenu from '../AdminConsole/AdminHeaderMenu';

const ActiveTemplateOptions = (props) => {
    const theme = useMantineTheme();
    const { id } = useParams();
    const [loading, setLoading] = useState(true);
    const [activeTemplates, setActiveTemplates] = useState([]);
    const [showTabs, setShowTabs] = useState(!!id);
    const [activeTab, setActiveTab] = useState(id ? String(id) : "default");
    const [err, setErr] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [postsPerPage] = useState(5);

    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    const currentPosts = activeTemplates.slice(indexOfFirstPost, indexOfLastPost);

    const navigate = useNavigate();

    const asyncGetActiveTemplates = async () => {
        await getActiveTemplates(setActiveTemplates, setLoading, setErr);
        return;
    };

    useEffect(() => {
        asyncGetActiveTemplates();
    }, [activeTab]);

    useEffect(() => {
        asyncGetActiveTemplates();
    }, []);

    return (<>
        <Tabs defaultValue="default" variant='default' color="cyan" value={activeTab} onTabChange={setActiveTab} keepMounted={false}>
            <Tabs.List bg={theme.colorScheme !== 'dark' && 'cyan-mb'} >
                <Image width={30} height={30} disabled src={logo} alt="Logo" />
                {AuthStore.isAdmin && (
                    <Tabs.Tab sx={{
                        '&:hover': {
                            backgroundColor: theme.colorScheme !== 'dark' && 'hsl(200, 100%, 13%)',
                        },
                    }}
                        c={theme.colorScheme !== 'dark' && 'cyan.0'}
                        rightSection={<Settings size={14} />}
                        value="*"
                        onClick={() => navigate('/admin')}>
                        AdminConsole</Tabs.Tab>)
                }
                <Tabs.Tab key={1} value="default" hidden >First tab</Tabs.Tab>
                {
                    activeTemplates && activeTemplates.map(template => {
                        return (
                            <Tabs.Tab
                                sx={{
                                    '&:hover': {
                                        backgroundColor: theme.colorScheme !== 'dark' && 'hsl(200, 100%, 13%)',
                                    },
                                }}
                                c={theme.colorScheme !== 'dark' && 'cyan.0'}
                                color={theme.colorScheme === 'dark' ? 'cyan-mb' : 'dark'}
                                key={template.id}
                                hidden={!showTabs
                                }
                                value={String(template.id)}
                                onClick={() => navigate(String(template.id))}
                            >
                                <Text c={theme.colorScheme !== 'dark' && 'cyan.0'}>{template.templateName}</Text>
                            </Tabs.Tab>)
                    })
                }
                <Group style={{ position: 'absolute', top: '5px', right: '0' }}>
                    <AdminHeaderMenu />
                </Group>
            </Tabs.List>
            {/* <Group position='right' style={{position: 'absolute', top:'5px', right: '0'}}> Welcome, {getUser()}!<Logout style={{cursor: 'pointer'}} onClick={()=> logout()}></Logout></Group> */}
            {activeTemplates && activeTemplates.map(template => {
                return (
                    <Tabs.Panel key={template.id} value={String(template.id)}>
                        <TemplateSelectedView id={template.id} setActiveTemplates={setActiveTemplates} setActiveTab={setActiveTab} />
                    </Tabs.Panel>
                )
            })}
            <Tabs.Panel value="default" style={{ marginTop: '3%' }}>
                <Center>
                    <Container>
                        <LoadingOverlay visible={loading} />
                        <Title align='center' order={2}>Boards</Title>
                        <Grid columns={2} style={{ marginTop: '3%' }}>
                            {activeTemplates.length == 0 ? <div>There are no active boards at this time!</div> : null}
                            {err && err}

                            {!loading && currentPosts.map((template, i) => {
                                return (
                                    <Grid.Col span={2} key={i} >
                                        <Card
                                            shadow='sm'
                                            radius='xs'
                                            withBorder
                                            key={template.id}
                                            style={{ cursor: 'pointer' }}
                                            onClick={() => {
                                                setActiveTab(String(template.id));
                                                setShowTabs(true);
                                                navigate(String(template.id));
                                            }}
                                        >
                                            <Title align='center' order={4}>{template.templateName}</Title>
                                        </Card>

                                    </Grid.Col>
                                )
                            })
                            }
                        </Grid>
                        <Pagination
                            style={{ paddingTop: '30px' }}
                            siblings={2}
                            page={currentPage}
                            onChange={setCurrentPage}
                            position="center"
                            total={Math.ceil(activeTemplates.length / postsPerPage)}
                        />
                    </Container>
                </Center>
            </Tabs.Panel>
        </Tabs>
    </>)
}

export default ActiveTemplateOptions

import { Text, TextInput, Title, Aside, Divider, Switch, Group, Collapse, Space, Badge, Stack, Button, Center, ScrollArea, LoadingOverlay, Grid, Tooltip } from '@mantine/core';
import { ColumnInsertLeft, Lock, Pencil, RowInsertTop, SquareMinus, SquarePlus, Trash } from 'tabler-icons-react';
import useTemplateDesigner from '../../../contexts/useTemplateDesigner';
import { useEffect, useState } from 'react';
import { SaveButton } from '../../SaveButton';
import useTemplates from '../../../contexts/useTemplates';
import useMagnetGroups from '../../../contexts/useMagnetGroups';
import { closeAllModals, closeModal, openConfirmModal, openModal } from '@mantine/modals';
import TemplateMagnetGroupTransferList from './TemplateMagnetGroupTransferList';
import { useNavigate } from 'react-router-dom';
import TemplateDuplicateForm from './TemplateDuplicateForm';
import { IconColumnRemove, IconRowRemove } from '@tabler/icons-react';

const TemplateDesignerToolPanel = (props) => {
    const [showTemplateSettings, setShowTemplateSettings] = useState(true);
    const [showEasyControl, setShowEasyControl] = useState(false);
    const [showCellSettings, setShowCellSettings] = useState(true);
    const [showMagnetGroups, setShowMagnetGroups] = useState(true);
    const {
        templateDesign,
        globalSettings,
        focusedCell,
        handleCellDataOnChange,
        handleChangeSettings,
        handleAddColSpan,
        handleRemoveColSpan,
        setTemplateDesign,
        saved,
        saving,
        saveTemplate,
        setTemplateDesignMagnetGroups,
        handleInsertRow,
        handleInsertColumn,
        handleRemoveRow,
        handleRemoveColumn,
        loading
    } = useTemplateDesigner();
    const { createTemplate, deleteTemplate, loadingTemplate, setLoadingTemplate } = useTemplates();
    const { magnetGroupList, getMagnetGroups, setMagnetGroupList } = useMagnetGroups();

    const navigate = useNavigate();

    useEffect(() => {
        getMagnetGroups();

        return () => {
            setMagnetGroupList([]);
        }
    }, [])

    const handleChangeMagnetGroups = () => {
        let ids = templateDesign.magnetgroups.map(({ id }) => id);
        openModal({
            title: (<Title order={6} align='center'>MagniGroups</Title>),
            children: (<TemplateMagnetGroupTransferList
                availableList={magnetGroupList.filter(({ id }) => !ids.includes(id))}
                usedList={templateDesign.magnetgroups}
                setAvailableList={setMagnetGroupList}
                setUsedList={setTemplateDesignMagnetGroups}
                closeModal={closeAllModals}
            />),
            size: 'xl'
        })
    }

    const confirmDelete = (template) => openConfirmModal({
        title: (<Center> Deleting Board:<Space w="xs" /><Text weight="bold"> {template?.templateName}</Text></Center>),
        children: (<Text align='center'>Are you sure you wish to continue?</Text>),
        labels: { confirm: 'Yes - Delete', cancel: 'Cancel' },
        confirmProps: { color: 'red' },
        onConfirm: () => {
            deleteTemplate(template?.id);
            navigate('/admin/boards');
        }
    })

    const handleSubmit = async (values) => {
        values.templateData.templateName = values.templateName;
        try {
            await createTemplate(values);
        } catch (ex) {
            console.error(ex);
        }
        closeModal && closeModal('duplicateform');
        navigate('/admin/boards');
    };

    const handleDuplicateForm = () => {
        openModal({
            modalId: 'duplicateform',
            children: (<><TemplateDuplicateForm templateDesign={templateDesign} handleSubmit={handleSubmit} /></>),
            size: 'md'
        })
    };

    const templateHeaderLabel = (<Group>Template Header<Switch size='xs' onLabel='On' offLabel='Off' checked={templateDesign?.showTemplateHeader} onChange={(e) => setTemplateDesign(prevGrid => { return { ...prevGrid, showTemplateHeader: e.target.checked } })} /></Group>)
    const DIVDER_SPACE = 5;

    return (<>
        <Aside width={{ base: 300 }} style={{ boxShadow: '-2px 0px 8px 0px rgba(0,0,0,0.5)', zIndex: 1 }} px={10}>
            {templateDesign?.id && (<Trash color='red' size={20} style={{ cursor: 'pointer', position: 'absolute', top: 0, right: 0 }} onClick={() => confirmDelete(templateDesign)} />)}
            <Aside.Section my='sm' mx><Title order={4} align='center' >Settings</Title></Aside.Section>
            <Divider />
            <Aside.Section component={ScrollArea} scrollbarSize={8} offsetScrollbars mt='sm' grow>
                <Divider
                    variant='dashed'
                    style={{ cursor: 'pointer' }}
                    labelPosition='left'
                    onClick={() => setShowTemplateSettings(!showTemplateSettings)}
                    label={<>
                        {showTemplateSettings ? (
                            <SquareMinus size={15} strokeWidth={.5} />
                        ) : (
                            <SquarePlus size={15} strokeWidth={.5} />
                        )}<Space w={DIVDER_SPACE} /><Text weight='bold'>Template Settings</Text></>} />
                <Collapse in={showTemplateSettings}>
                    <TextInput
                        size='xs'
                        label='Template Name'
                        placeholder="Template Name"
                        value={templateDesign?.templateName || ''}
                        onChange={(e) => setTemplateDesign(prevGrid => { return { ...prevGrid, templateName: e.target.value } })}
                    />
                    <TextInput
                        size='xs'
                        label={templateHeaderLabel}
                        placeholder="Template Header"
                        value={templateDesign?.templateHeader || ''}
                        onChange={(e) => setTemplateDesign(prevGrid => { return { ...prevGrid, templateHeader: e.target.value } })}
                        disabled={!templateDesign?.showTemplateHeader}
                    />
                </Collapse>
                <br />
                <Divider
                    variant='dashed'
                    style={{ cursor: 'pointer' }}
                    labelPosition='left'
                    onClick={() => setShowEasyControl(!showEasyControl)}
                    label={<>
                        {showEasyControl ? (
                            <SquareMinus size={15} strokeWidth={.5} />
                        ) : (
                            <SquarePlus size={15} strokeWidth={.5} />
                        )}<Space w={DIVDER_SPACE} /> <Text weight='bold' >Easy Controls</Text></>} />
                <Collapse in={showEasyControl}>
                    <Switch label="Show All"
                        onChange={(e) => handleChangeSettings(e, 'AllGlobalSettings', e.target.checked)}
                        checked={Object.values(globalSettings).every(value => value === true)}
                    />
                    <Switch label="Show Magnets"
                        onChange={(e) => handleChangeSettings(e, 'showMagnets', e.target.checked)}
                        checked={globalSettings.showMagnets}
                    />
                    <Switch label="Show Droppable CheckBoxes"
                        onChange={(e) => handleChangeSettings(e, 'showDroppableCheckBoxes', e.target.checked)}
                        checked={globalSettings.showDroppableCheckBoxes}
                    />
                    <Switch label="Show Cell Text Inputs"
                        onChange={(e) => handleChangeSettings(e, 'showTextInputs', e.target.checked)}
                        checked={globalSettings.showTextInputs}
                    />
                    <Switch label="Show Header Checkboxes"
                        onChange={(e) => handleChangeSettings(e, 'showHeaderCheckBoxes', e.target.checked)}
                        checked={globalSettings.showHeaderCheckBoxes}
                    />
                </Collapse>


                <br />
                <Divider
                    variant='dashed'
                    style={{ cursor: 'pointer' }}
                    labelPosition='left'
                    onClick={() => setShowCellSettings(!showCellSettings)}
                    label={<>{showCellSettings ? (<SquareMinus size={15} strokeWidth={.5} />) : (<SquarePlus size={15} strokeWidth={.5} />)}
                        <Space w={DIVDER_SPACE} /><Text weight='bold'>Cell Settings</Text>
                    </>}
                />
                <Collapse in={showCellSettings}>
                    {/* <Group position='center'> */}
                    <Grid>
                        <Grid.Col span={3}>
                            <Stack spacing={4} style={{ paddingTop: '5px' }}>
                                <Badge radius={0}>x:{focusedCell.x}</Badge>
                                <Badge radius={0}>y:{focusedCell.y}</Badge>
                            </Stack>
                        </Grid.Col>
                        <Grid.Col span={2}>
                            <Stack direction="vertical" align="start" spacing={4} style={{ paddingTop: '2px' }}>
                                <Tooltip label='Add Row Above'>
                                    <Button size='compact-xs' disabled={focusedCell.x === ''}
                                        onClick={() => handleInsertRow(focusedCell.y)}
                                    >
                                        <RowInsertTop />
                                    </Button>
                                </Tooltip>
                                <Tooltip label='Add Column Left' position='bottom'>
                                    <Button size='compact-xs' disabled={focusedCell.x === ''}
                                        onClick={() => handleInsertColumn(focusedCell.x, focusedCell.y)}
                                    >
                                        <ColumnInsertLeft />
                                    </Button>
                                </Tooltip>
                            </Stack>
                        </Grid.Col>
                        <Grid.Col span={3}>
                            <Stack direction="vertical" align="start" spacing={4} style={{ paddingTop: '2px' }}>
                                <Tooltip label='Remove Selected Row' position='top'>
                                    <Button color='red' size='compact-xs' disabled={focusedCell.x === ''} onClick={() => handleRemoveRow(focusedCell.y)}><IconRowRemove /></Button>
                                </Tooltip>
                                <Tooltip label='Remove Selected Column' position='bottom'>
                                    <Button color='red' size='compact-xs' disabled={focusedCell.x === ''} onClick={() => handleRemoveColumn(focusedCell.x)}><IconColumnRemove /></Button>
                                </Tooltip>
                            </Stack>
                        </Grid.Col>
                    </Grid>
                    {/* </Group> */}
                    <TextInput
                        size='xs'
                        label='Cell Text'
                        placeholder="Cell Text"
                        value={focusedCell.cell.text}
                        onChange={(e) => handleCellDataOnChange({ ...focusedCell, cell: { ...focusedCell.cell, text: e.target.value } })}
                    />
                    <Group position='left'>
                        <Text align='center' size='xs'>Column Span:</Text>
                        <SquareMinus style={{ cursor: 'pointer' }} size={25} strokeWidth={1} onClick={(e) => handleRemoveColSpan({ ...focusedCell, cell: { ...focusedCell.cell, colSpan: focusedCell.cell.colSpan - 1 } })} />
                        <Badge radius={0}>{focusedCell && focusedCell.cell.colSpan}</Badge>
                        <SquarePlus style={{ cursor: 'pointer' }} size={25} strokeWidth={1} onClick={(e) => handleAddColSpan({ ...focusedCell, cell: { ...focusedCell.cell, colSpan: focusedCell.cell.colSpan + 1 } })} />
                    </Group>
                    <Divider variant='dashed' size='xs' label='Cell Attributes' />
                    <Switch size='xs' label="Droppable" checked={focusedCell.cell.droppable} onChange={(e) => handleCellDataOnChange({ ...focusedCell, cell: { ...focusedCell.cell, droppable: e.target.checked } })} />
                    <Switch size='xs' label="Header Cell" checked={focusedCell.cell.header} onChange={(e) => handleCellDataOnChange({ ...focusedCell, cell: { ...focusedCell.cell, header: e.target.checked } })} />
                </Collapse>
                <br />
                <Divider
                    variant='dashed'
                    style={{ cursor: 'pointer' }}
                    labelPosition='left'
                    onClick={() => setShowMagnetGroups(!showMagnetGroups)}
                    label={<>
                        {showMagnetGroups ? (
                            <SquareMinus size={15} strokeWidth={.5} />
                        ) : (
                            <SquarePlus size={15} strokeWidth={.5} />
                        )}<Space w={DIVDER_SPACE} /><Text weight='bold'>MagniGroups</Text></>} />
                <Collapse in={showMagnetGroups}>
                    <Space h={15} />
                    <Group position='center'>
                        <Button
                            style={{ boxShadow: '0px 3px 5px rgba(0, 0, 0, .6)', }}
                            size='xs'
                            radius='sm'
                            variant='light'
                            compact
                            leftIcon={(<Pencil size={20} />)}
                            onClick={() => { handleChangeMagnetGroups() }}
                        >Modify MagniGroups</Button>
                    </Group>

                    <Stack align='stretch' justify='flex-start' p={25} spacing={5}>
                        {templateDesign && !loading && templateDesign?.magnetgroups.map((group, i) => {
                            return (<Badge rightSection={group.id === 1 && (<Lock size={15} />)} p={10} key={'magnigroup-' + i} variant='outline' size='lg' radius='sm' >{group.name}</Badge>)
                        })}
                    </Stack>

                </Collapse>
            </Aside.Section>
            <Divider />
            <Aside.Section>
                <Group position={templateDesign?.id ? 'apart' : 'center'} my='sm'>
                    {templateDesign?.id ? <Button onClick={handleDuplicateForm}>Duplicate Board</Button> : null}
                    <SaveButton
                        boolSaveOrCreate={Boolean(templateDesign?.id)}
                        data={templateDesign}
                        saveFunction={saveTemplate}
                        createFunction={createTemplate}
                        loading={saving} disabled={saved} />
                </Group>
            </Aside.Section>
        </Aside>
    </>);
}

export default TemplateDesignerToolPanel;
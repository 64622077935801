import { useDidUpdate } from "@mantine/hooks";
import { showNotification } from "@mantine/notifications";
import { createContext, useState, useEffect } from "react";
import { GetTemplateById, GetTemplates, PutTemplate, SetTemplateStatusFromList } from '../exports/fetch/TemplateFetch';
import useAuth from './contextHooks/useAuth';
import { useNavigate } from "react-router";

const TemplateDesignerContext = createContext({});

export const TemplateDesignerProvider = ({ children }) => {
    const INITIAL_GRID = {
        isActive: false,
        templateName: 'My template',
        templateHeader: 'My first grid',
        magnetgroups: [{
            id: 1,
            name: "Default - Global"
        }],
        showTemplateHeader: true,
        "rows": [
            {
                "rowOrder": 0,
                "cells": [
                    {
                        "cellOrder": 0,
                        "colSpan": 1,
                        "rowSpan": 0,
                        "header": true,
                        "droppable": false,
                        "text": "Header Cell"
                    },
                    {
                        "cellOrder": 1,
                        "colSpan": 1,
                        "rowSpan": 0,
                        "header": true,
                        "droppable": false,
                        "text": ""
                    }
                ]
            },
            {
                "rowOrder": 1,
                "cells": [
                    {
                        "cellOrder": 0,
                        "colSpan": 1,
                        "rowSpan": 0,
                        "header": false,
                        "droppable": true,
                        "text": ""
                    },
                    {
                        "cellOrder": 1,
                        "colSpan": 1,
                        "rowSpan": 0,
                        "header": false,
                        "droppable": true,
                        "text": ""
                    }
                ]
            }
        ]
    }

    const [templateDesign, setTemplateDesign] = useState({});
    const [showToolPanel, setShowToolPanel] = useState(false);
    const [loading, setLoading] = useState(true);
    const [focusedCell, setFocusedCell] = useState({ cell: { text: '' }, x: '', y: '' });
    const [globalSettings, setGlobalSettings] = useState({
        showDroppableCheckBoxes: false,
        showMagnets: false,
        showTextInputs: false,
        showHeaderCheckBoxes: false,
    });
    const [saved, setSaved] = useState(true);
    const [saving, setSaving] = useState(false);
    const { getToken } = useAuth();
    const navigate = useNavigate();

    const loadEmptyTemplate = () => {
        setTemplateDesign(INITIAL_GRID);
        setLoading(false);
        setShowToolPanel(true);
    }
    const getTemplateDesignById = async (id) => {
        setLoading(true);
        const data = await GetTemplateById(id);
        setTemplateDesign(data);
        setLoading(false);
        setShowToolPanel(true);
    }
    const updateSettings = () => {

    }
    const updateFocusedCell = (cell) => { setFocusedCell(cell); }
    const handleCellDataOnChange = (data) => {
        let y = data.y;
        let x = data.x;
        setTemplateDesign(prevGrid => {
            return {
                ...prevGrid,
                rows: prevGrid.rows.map((row, yIndex) => {
                    return {
                        ...row, cells: row.cells.map((cell, xIndex) => {
                            if (xIndex === x && yIndex === y) { return data.cell }
                            else return cell;
                        })
                    }
                })
            }
        })
        setFocusedCell(data)

    }
    const handleInsertRow = (insertIndex) => {
        setTemplateDesign((prevState) => {
            let newRows = prevState.rows;
            let newRow = {
                cells: []
            };
            prevState.rows[0].cells.forEach((c, i) => {
                newRow.cells.push({
                    rowSpan: 0,
                    colSpan: 1,
                    header: false,
                    droppable: true,
                    text: '',
                    cellOrder: i
                })
            });
            newRows.splice(insertIndex, 0, newRow);
            newRows.forEach((row, rowIndex) => {
                row.rowOrder = rowIndex;
            })
            return { ...prevState, newRows }
        })
    }
    const handleAddRow = (e) => {
        setTemplateDesign((prevState) => {
            let newRows = prevState.rows;
            let newRow = {
                rowOrder: prevState.rows.length,
                cells: []
            };
            prevState.rows[0].cells.forEach((c, i) => {
                newRow.cells.push({
                    rowSpan: 0,
                    colSpan: 1,
                    header: false,
                    droppable: true,
                    text: '',
                    cellOrder: i
                })
            });
            newRows.push(newRow);
            return { ...prevState, newRows }
        })
    }
    const handleRemoveRow = (removeIndex) => {
        setTemplateDesign(prevState => {
            let newRows = prevState.rows;
            if (removeIndex >= 0) {
                if (removeIndex >= 0 && removeIndex < newRows.length) {
                    newRows.splice(removeIndex, 1);
                    //newRows.slice(removeIndex).forEach((row) => { row.rowOrder -= 1; });
                }
            }
            else {
                if (prevState.rows.length === 1) return prevState;
                newRows.pop();
            }

            newRows.forEach((row, rowIndex) => {
                row.rowOrder = rowIndex;
            })

            return { ...prevState, newRows }
        })
    }
    const handleInsertColumn = (insertIndexX, insertIndexY) => {
        let newCell = {
            cellOrder: insertIndexX,
            rowSpan: 0,
            header: false,
            droppable: true,
            text: ''
        }

        setTemplateDesign(prevState => {
            let newRows = prevState.rows;
            newRows.forEach((row, rowIndex) => {
                const targetCell = row?.cells[insertIndexX];
                let newColSpan = 1;
                if (targetCell.colSpan == 0) {
                    newColSpan = 0;
                    //increment the first cell of the col span
                    row?.cells.forEach((cell, cellIndex) => {
                        if (cellIndex >= insertIndexX) return;
                        if (cell.colSpan + cellIndex >= insertIndexX) {
                            cell.colSpan++;
                        }
                    });
                }
                else if (targetCell.colSpan >= 1) {
                    newColSpan = 1;
                }
                row?.cells.splice(insertIndexX, 0, { ...newCell, colSpan: newColSpan });
                row?.cells.forEach((cell, cellIndex) => {
                    cell.cellOrder = cellIndex;
                    //FOR DEBUG cell.text += `: ${rowIndex},${cellIndex}`;
                })
            });

            return { ...prevState, newRows }
        });
    }
    const handleAddColumn = (e) => {
        setTemplateDesign(prevState => {
            let newRows = prevState.rows;
            newRows.forEach(row => {
                row?.cells.push({
                    cellOrder: row?.cells?.length,
                    colSpan: 1,
                    rowSpan: 0,
                    header: false,
                    droppable: true,
                    text: ''
                })
            });
            return { ...prevState, newRows }
        });
    }
    const handleRemoveColumn = (removeIndex) => {
        setTemplateDesign(prevState => {
            let newRows = prevState.rows;
            if (removeIndex >= 0) {
                const newRows = prevState.rows.map((row) => {
                    if (removeIndex < row.cells.length) {
                        const cellToRemove = row.cells[removeIndex];
                        if (cellToRemove) {
                            if (cellToRemove.colSpan > 1) {
                                //change the next one to the right to colSpan -1
                                if (removeIndex + 1 < row.cells.length) {
                                    row.cells[removeIndex + 1].colSpan = (cellToRemove.colSpan - 1);
                                    row.cells[removeIndex + 1].droppable = cellToRemove.droppable;
                                }
                            } else if (cellToRemove.colSpan === 0) {
                                //go left until row.cells.colSpan > 0 and change to - 1
                                for (let i = removeIndex - 1; i >= 0; i--) {
                                    if (row.cells[i].colSpan > 0) {
                                        row.cells[i].colSpan--;
                                        break;
                                    }
                                }
                            }
                        }

                        row.cells.splice(removeIndex, 1);
                        row.cells.slice(removeIndex).forEach(cell => {
                            cell.cellOrder -= 1;
                        });
                    }
                    return row;
                });
                return { ...prevState, newRows };
            }
            else {
                if (prevState.rows[0].cells.length === 1) return prevState;
                newRows.forEach((row) => {
                    if (row.cells[row.cells.length - 1].colSpan === 0) {
                        for (let i = row?.cells.length - 1; i >= 0; i--) {
                            if (row.cells[i].colSpan > 1) {
                                row.cells[i].colSpan = row?.cells[i].colSpan - 1;
                                break;
                            }
                        }
                    }
                    row?.cells.pop();
                });
            }
            return { ...prevState, newRows }
        })
    }
    const handleChangeSettings = (e, key, checked) => {
        if (key === 'AllGlobalSettings') setGlobalSettings(Object.keys(globalSettings).reduce((acc, property) => { return { ...acc, [property]: checked } }, {}))
        else setGlobalSettings(prevSettings => { return { ...prevSettings, [key]: checked } })
    }
    const handleAddColSpan = (data) => {
        //update targetCell
        if (data.x + data.cell.colSpan > templateDesign.rows[0].cells.length) return null;
        if (templateDesign.rows[data.y].cells[data.x + data.cell.colSpan - 1].colSpan > 1) return null;
        setTemplateDesign(prevGrid => {
            return {
                ...prevGrid,
                rows: prevGrid.rows.map((row, yIndex) => {
                    return {
                        ...row, cells: row.cells.map((cell, xIndex) => {
                            //update targetCell
                            if ((data.x + data.cell.colSpan) > row.cells.length) {
                                return cell;
                            }
                            else if (yIndex === data.y && xIndex === data.x) {
                                return { ...cell, colSpan: data.cell.colSpan }
                            }
                            else if (yIndex === data.y && xIndex === (data.x + data.cell.colSpan - 1) && (data.x + data.cell.colSpan) <= row.cells.length) {
                                return { ...cell, colSpan: 0 }
                            }
                            else return cell;
                        })
                    }
                })

            }
        })
        //update cells after it
        setFocusedCell(data)
    }
    const handleRemoveColSpan = (data) => {
        //update targetCell
        if (data.cell.colSpan < 1) return null;
        setTemplateDesign(prevGrid => {
            return {
                ...prevGrid,
                rows: prevGrid.rows.map((row, yIndex) => {
                    return {
                        ...row, cells: row.cells.map((cell, xIndex) => {
                            //update targetCell
                            if (data.cell.colSpan < 1) {
                                return cell;
                            }
                            else if (yIndex === data.y && xIndex === data.x) {
                                return data.cell
                            }
                            else if (yIndex === data.y && xIndex === (data.x + data.cell.colSpan) && (data.x + data.cell.colSpan) <= row.cells.length) {
                                return { ...cell, colSpan: 1 }
                            }
                            else return cell;
                        })
                    }
                })

            }
        })
        //update cells after it
        setFocusedCell(data)
    }
    const saveTemplate = (templateDesign) => {
        setSaving(true);
        PutTemplate(templateDesign).then((res) => {
            setSaved(true);
            setSaving(false);
            navigate('/admin/boards');
            if (!res.ok) {
                switch (res.status) {
                    case (413):
                        showNotification({
                            title: 'Error',
                            message: "Table is too large",
                            color: 'red'
                        })
                        break;
                    default:
                        showNotification({
                            title: 'Error',
                            message: res.status + " - " + res.statusText,
                            color: 'red'
                        })
                }

            }
        }).catch((err) => {
            console.error(err);
        });
    }
    const clearTemplateDesign = () => {
        setTemplateDesign(null);
        setShowToolPanel(false);
    }
    const setTemplateDesignMagnetGroups = (groups) => {
        setTemplateDesign(prevGrid => { return { ...prevGrid, magnetgroups: groups } })
    }
    //moved to template designer
    //    useDidUpdate(() => {
    //         setSaved(false);
    //    }, [templateDesign])

    return (<>
        <TemplateDesignerContext.Provider value={{
            templateDesign,
            getTemplateDesignById,
            globalSettings,
            updateSettings,
            focusedCell,
            updateFocusedCell,
            handleCellDataOnChange,
            handleAddRow,
            handleInsertRow,
            handleRemoveRow,
            handleAddColumn,
            handleInsertColumn,
            handleRemoveColumn,
            handleChangeSettings,
            handleAddColSpan,
            handleRemoveColSpan,
            setTemplateDesign,
            loadEmptyTemplate,
            setTemplateDesignMagnetGroups,
            clearTemplateDesign,
            saved,
            setSaved,
            saving,
            saveTemplate,
            loading,
            setLoading,
            showToolPanel,
            setShowToolPanel
        }}>
            {children}
        </TemplateDesignerContext.Provider>
    </>)
}

export default TemplateDesignerContext; 
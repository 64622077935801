import { Input } from '@mantine/core';
import { Search, X } from 'tabler-icons-react';

const SearchBar = ({ wordEntered, setWordEntered, placeholder, data, searchType }) => {
    const handleFilter = (e) => {
        const searchWord = e.target.value;
        setWordEntered(searchWord);
        const newFilter = data.filter((value) => {
            if (searchType === 'magnet') return value?.magnetName.toLowerCase().includes(searchWord.toLowerCase());
            else if (searchType === 'board') return value?.templateName.toLowerCase().includes(searchWord.toLowerCase());
            else if (searchType === 'archive') {
                return (
                    value?.templateObject?.templateName.toLowerCase().includes(searchWord.toLowerCase()) ||
                    new Date(value?.timestamp).toLocaleString().includes(searchWord.toLowerCase())
                )
            }
            return true;
        });
    }

    const clearInput = () => {
        setWordEntered('');
    }

    const clearButton = {
        cursor: 'pointer'
    }

    return (
        <div>
            <div>
                <Input className="search_input"
                    type="text"
                    id="search"
                    size='xs'
                    radius="md"
                    value={wordEntered}
                    placeholder={placeholder}
                    rightSection={
                        <div>
                            {wordEntered.length === 0 ?
                                <Search />
                                :
                                <X style={clearButton} onClick={clearInput} />
                            }
                        </div>
                    }
                    onChange={handleFilter}
                />
            </div>
        </div>
    )
}

export default SearchBar

import { useEffect } from 'react';
import { MagnetForDisplayOnly } from "../../ViewComponents/MagnetComponent";
import { Container, Title, Text, Space, Center, LoadingOverlay } from '@mantine/core';
import { Trash } from 'tabler-icons-react'
import MagnetForm from './MagnetForm';
import useMagnets from '../../../contexts/useMagnets';
import { closeModal, openConfirmModal, openModal } from '@mantine/modals';
import useSettings from '../../../contexts/contextHooks/useSettings';
import { TableSort } from '../../Utilities/TableSort';

const MagnetList = ({ magnetGroup, groupData }) => {
    const {
        magnetList,
        deleteMagnet,
        startMagnetListRefresh,
        stopMagnetListRefresh,
        loading,
        startMagnetListByGroupIdRefresh
    } = useMagnets();
    const { fullNavbar } = useSettings();

    useEffect(() => {
        groupData?.id !== undefined ? startMagnetListByGroupIdRefresh(groupData?.id) : startMagnetListRefresh();
        return () => stopMagnetListRefresh();
    }, []);

    const confirmDelete = (magnet) => openConfirmModal({
        title: (<Center> Deleting Magnet:<Space w="xs" /><Text weight="bold"> {magnet?.magnetName}</Text></Center>),
        children: (<Text align='center'>Are you sure you wish to continue?</Text>),
        labels: { confirm: 'Yes - Delete', cancel: 'Cancel' },
        confirmProps: { color: 'red' },
        onConfirm: () => {
            deleteMagnet(magnet);
        }
    })

    const handleMagnetForm = (magnetData) => {
        stopMagnetListRefresh();
        openModal({
            modalId: 'magnetForm',
            children: (
                <MagnetForm
                    groupData={groupData}
                    magnetData={magnetData}
                    onSubmit={closeModal}
                />
            ),
            size: 'xl',
            closeOnClickOutside: false,
        })
    };

    return (
        <>
            {magnetList && (
                <Container size="md" fluid pr={{ xl: fullNavbar ? '300px' : null }} style={{ marginTop: '8vh' }}>
                    <LoadingOverlay visible={loading} />
                    <Title align="center" order={3}>{magnetGroup ? magnetGroup : 'Magnets'}</Title>
                    {magnetList && (
                        <TableSort
                            initialSortBy='Magnet Name'
                            postsPerPage={8}
                            tableLayout='fixed'
                            newButtonText='New Magnet'
                            handleClickNew={handleMagnetForm}
                            handleClickItem={(id) => handleMagnetForm(magnetList.filter(m => String(m?.id) === String(id))[0])}
                            data={magnetList.length > 0 && magnetList.map((m, i) => {
                                return {
                                    id: String(m?.id),
                                    "Magnet Name": m.magnetName,
                                    "Color Scheme": {
                                        value: undefined,
                                        view: (<><MagnetForDisplayOnly key={'samplemagnet' + m.id} defaultText='<Sample>' data={m} /></>)
                                    },
                                    "Delete": {
                                        value: undefined,
                                        view: (<Trash key={'delete' + m.id} style={{ cursor: 'pointer' }} onClick={() => confirmDelete(m)} />)
                                    }
                                }
                            })}
                        />
                    )}
                </Container>)}
        </>
    )
}

export default MagnetList

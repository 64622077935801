import React from 'react'
import { createContext, useState, useEffect, useRef } from "react";
import { GetArchiveJobs } from '../exports/fetch/ArchiveSchedulerFetch';

const ArchiveSchedulerContext = createContext({});

export const ArchiveSchedulerProvider = ({ children }: any) => {
    const [scheduleList, setScheduleList] = useState([]);
    const [loading, setLoading] = useState(true);
    const refreshInterval = useRef<any>(null);

    const getSchedulerJobs = async () => {
        const data = await GetArchiveJobs();
        setScheduleList(data);
        setLoading(false);
    }

    const stopArchiveListRefresh = () => {
        clearInterval(refreshInterval.current);
    }

    const startArchiveListRefresh = () => {
        setLoading(true);
        getSchedulerJobs();
        refreshInterval.current = setInterval(() => {
            getSchedulerJobs();
        }, 10000);
    }

    useEffect(() => {
        return () => clearInterval(refreshInterval.current);
    }, []);

    return (
        <>
            <ArchiveSchedulerContext.Provider value={{ scheduleList, startArchiveListRefresh, stopArchiveListRefresh, loading, getSchedulerJobs }}>
                {children}
            </ArchiveSchedulerContext.Provider>
        </>
    )
}

export default ArchiveSchedulerContext; 
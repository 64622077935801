import { Center, Space, useMantineTheme } from '@mantine/core';
import React from 'react'
import { useDrop, useDrag } from 'react-dnd';

function DroppableCell(props) {
  const theme = useMantineTheme();
  const [{ isOver, canDrop }, drop] = useDrop(() => ({
    accept: 'magnet',
    drop: (magnet, monitor) => addMagnetToTemplate(magnet, monitor),
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
      canDrop: !!monitor.canDrop(),
    })
  }))

  const addMagnetToTemplate = (magnet, monitor) => {
    magnet.destinationLoc = props.destinationLoc;
    props.moveMagnet(props.coordinates, magnet);
  }

  return (
    <div ref={drop} style={{
      cursor: isOver ? 'grabbing' : 'default',
      height: '100%',
      width: '100%',
      //minHeight: '100%',
      margin: 0,
      display: 'flex',
      justifyContent: 'center',
      padding: 0,
      //hsl(200, 100%, 10%)
      //hsl(200, 100%, 1%)
      background: canDrop ? (
        props.bgColor ? (isOver ? '#FF8787' : props.bgColor) : (isOver ? theme.colorScheme == 'dark' ? 'hsl(200, 100%, 15%)' : 'lightgreen' : '')
      ) : 'none'
    }} className={isOver ? "isOver droppablecell" : "droppablecell"}><Center>{props.bgColor ? isOver && props.children : props.children}</Center></div>
  )
}

export default DroppableCell
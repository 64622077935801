import React, { useEffect, useState } from 'react'
import { ADPFormWorkerTable } from './ADPFormWorkerTable';
import { ADPFormDisplayData } from './ADPFormDisplayData';
import { PluginStore } from '../../Plugins/PluginStore';

const MagnetFormADP = ({ magnetData, form }: any) => {
    const [isADPLinked, setIsADPLinked] = useState(false);
    const [showADPWorkerForm, setShowADPWorkerForm] = useState(false);
    const [ADPWorker, setADPWorker] = useState<any>();

    useEffect(() => {
        PluginStore.getPlugins();
        !ADPWorker && setIsADPLinked(!!magnetData?.pluginxmagnet);
        !ADPWorker && setShowADPWorkerForm(!!magnetData?.pluginxmagnet);
    }, []);

    const loadADPForm = () => {
        return showADPWorkerForm ?
            (
                <ADPFormDisplayData
                    magnetData={magnetData}
                    workerData={ADPWorker}
                    setADPWorker={setADPWorker}
                    form={form}
                    isADPLinked={isADPLinked}
                    setIsADPLinked={setIsADPLinked}
                    setShowADPWorkerForm={setShowADPWorkerForm}
                />
            ) : (
                <ADPFormWorkerTable
                    setADPWorker={setADPWorker}
                    setShowADPWorkerForm={setShowADPWorkerForm}
                />
            );
    }

    return loadADPForm();
}

export default MagnetFormADP

import { API_URL, customErrorNotification } from './GlobalFetchSettings.js'
import { privateFetch as fetch } from '../../hooks/usePrivateFetch.js';
const PLUGIN_URL = API_URL + '/Plugin';

//get request
export const GetPlugins = async () => {
    try {
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            }
        };
        const response = await fetch(PLUGIN_URL, requestOptions);
        const data = await response.json();
        return data;
    }
    catch (e) {
        console.error("ERROR: " + e)
    }
};

export const GetADPWorkersPlugin = async (id) => {
    try {
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            }
        };
        const response = await fetch(PLUGIN_URL + '/adpworkerintegration/' + id, requestOptions)
        const data = await response.json();
        return data;
    }
    catch (e) {
        console.error("ERROR: " + e)
    }
}
export const GetPluginById = async (id) => {
    try {
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            }
        };
        const response = await fetch(PLUGIN_URL + '/' + id, requestOptions)
        return response.json();
    }
    catch (e) {
        console.error("ERROR: " + e)
    }
}

export const GetADPWorkerByAOID = async (id) => {
    try {
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            }
        };
        const response = await fetch(PLUGIN_URL + '/adpworkerfetch/' + id, requestOptions)
        const data = await response.json();
        return data;
    }
    catch (e) {
        console.error("ERROR: " + e)
    }
}

export const GetADPWorkerImage = async (id) => {
    try {
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'image/png',
            }
        };
        const response = await fetch(PLUGIN_URL + '/adpworkerimage/' + id, requestOptions)
        const data = await response.text();
        return data;
    }
    catch (e) {
        console.error("ERROR: " + e)
    }
}

export const GetLinkedMagnets = async () => {
    try {
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            }
        };
        const response = await fetch(PLUGIN_URL + '/GetLinkedMagnets', requestOptions)
        const data = await response.json();
        return data;
    }
    catch (e) {
        console.error("ERROR: " + e)
    }
};

export const LinkPluginXMagnet = async (body) => {
    try {
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(body)
        };
        const response = await fetch(PLUGIN_URL + '/linkmagnet', requestOptions)
        if (response.ok) return response.json();
        else if (response.status === 400) customErrorNotification(response, 'failed to create ADP / Magnet Link')
        else customErrorNotification(response);
        return false;
    }
    catch (e) {
        console.error("ERROR: " + e)
    }
};

export const UnlinkPluginXMagnet = async (id) => {
    try {
        const requestOptions = {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
            }
        };
        const response = await fetch(PLUGIN_URL + '/unlinkmagnet/' + id, requestOptions)
        if (response.ok) return true;
        else if (response.status === 404) customErrorNotification(response, 'failed to unlink magnet from ADP data')
        else customErrorNotification(response);
        return false;
    }
    catch (e) {
        console.error("ERROR: " + e)
    }
};
import React, { useEffect, useState } from "react";
import { observer } from 'mobx-react';
import { Center, Container, Paper, Text, Title } from "@mantine/core";
import { PluginStore, PluginClass } from "./PluginStore";
import { useParams } from "react-router-dom";
import { TableSort } from "../../Utilities/TableSort";
import { GetLinkedMagnets } from "../../../exports/fetch/PluginFetch";


interface PluginListProps {
    //
}

const PluginConfig = ({ }: PluginListProps) => {
    const [plugin, setPlugin] = useState<PluginClass | null>(null);
    const { id } = useParams();
    useEffect(() => {
        const fetchData = async () => {
            const linkedMagnets = await GetLinkedMagnets();
            const pluginData = await PluginStore.getADPWorkersByPluginId(Number(id));
            if (pluginData) {
                setPlugin({ ...pluginData });
            }
        };
        fetchData();
    }, [])

    const hideButton = () => { return (<></>) }

    return (<>
        <Container mt='lg'>
            <Title size={25}>*Note from the developers</Title>
            <Text>This page is unfinished and currenlty only displays records found by the adp integration.</Text>
            <Paper shadow='md' p='md'>
                {plugin && (
                    <TableSort
                        initialSortBy='Employee'
                        postsPerPage={10}
                        tableLayout='auto'
                        componentButton={hideButton}
                        handleClickItem={() => null}
                        data={plugin?.stored_object?.slice(1).map((worker: any, index: number) => ({
                            id: String(index),
                            'Worker ID': worker.workerID,
                            'Employee': worker.legalName.formattedName,
                            'Department': worker.department.longName,
                            'Magnet': 'placeholder'
                        })) ?? []}
                    />
                )}
                {/* {plugin ? JSON.stringify(plugin, null, "\t") : ('still loading')} */}
            </Paper>
        </Container >
    </>);
}
export default observer(PluginConfig);
import { Paper, Container, Title, Text, Center, Button } from "@mantine/core";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom"
import { ArrowBigLeft } from "tabler-icons-react";

const Unauthorized = () => {
    const navigate = useNavigate();
    const [counter, setCounter] = useState(10);

    useEffect(() => {
        // const interval = setInterval(() => {
        //     setCounter(prevCounter => prevCounter - 1);
        // }, 1000);
        // return () => clearInterval(interval);
    }, [])

    useEffect(() => {
        if (counter <= 0) navigate(-1);
    }, [counter])

    return (<>
        <Container padding={200}>
            <Paper withBorder shadow='md' padding='md' style={{ width: '350px', height: '200px', margin: '10% auto' }}>
                <Title order={3} align='center'>Unauthorized!</Title>
                <Text align="center">You do not have access to the requested page.</Text>
                <br />
                <Text align='center'>Going back to previous page in {counter} seconds</Text>
                <Center><Button variant='subtle' onClick={() => navigate(-1)}><ArrowBigLeft /></Button></Center>
            </Paper>
        </Container>

    </>)
}

export default Unauthorized
import { Button, Group, Select } from '@mantine/core'
import { TimeInput } from '@mantine/dates';
import { useForm } from '@mantine/form';
import { IconClock } from '@tabler/icons';
import React from 'react'
import { NewArchiveJob } from '../../../exports/fetch/ArchiveSchedulerFetch';
import { AuthStore } from '../../../stores/authStore';
import { ArchiveSchedulerTemplate } from './ArchiveSchedulerStore';

interface propType {
    SchedulerObj: any,
    templateListData: any,
    closeModal: any,
    onSubmit: any
}

const ArchiveSchedulerForm = ({ SchedulerObj, templateListData, closeModal, onSubmit }: propType) => {
    const form = useForm<ArchiveSchedulerTemplate>({
        initialValues: {
            templateId: 0,
            interval: 'WEEKDAY',
            time: ''
            //createdBy: AuthStore?.username
        }
    });

    let handleSubmit = async (formOptions: ArchiveSchedulerTemplate) => {
        SchedulerObj.addJob(formOptions);
        //await NewArchiveJob(formOptions);
        onSubmit && onSubmit('archiveSchedulerForm');
    };

    return (
        <form onSubmit={form.onSubmit((values: ArchiveSchedulerTemplate) => handleSubmit(values))}>
            <Select
                required
                label="Select a board to archive"
                placeholder="Boards"
                data={templateListData.map((template: any) => {
                    return (
                        { key: template.id, value: template.id, label: template.templateName }
                    )
                })}
                {...form.getInputProps('templateId')}
            />
            <Select
                required
                label="Interval"
                defaultValue={'weekday'}
                placeholder="Weekdays (Monday - Friday)"
                data={[{ value: 'WEEKDAYS', label: 'WEEKDAYS ONLY (Monday - Friday)' }, { value: 'DAILY', label: 'DAILY' }]}
                {...form.getInputProps('interval')}
            />
            <TimeInput
                required
                label="Select time of archive"
                format="12"
                icon={<IconClock size={16} />}
                {...form.getInputProps('time')}
            />

            <Group position="right" mt="md">
                {
                    !form.values.templateId || !form.values.interval || !form.values.time ?
                        <Button type="submit" disabled>Submit</Button>
                        :
                        <Button type="submit">Submit</Button>
                }
            </Group>
        </form>
    )
}

export default ArchiveSchedulerForm

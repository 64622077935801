import { useEffect } from 'react';
import { useDrag } from 'react-dnd';
import { Button, Center, Flex, Text, Tooltip, useMantineTheme } from '@mantine/core';
import { Stack3 } from 'tabler-icons-react';
import { TTC_Activity_Circle } from './VC-Plugins/ADPTTCClocked_in';

export function Magnet(props) {
    const theme = useMantineTheme();
    const [{ isDragging }, drag] = useDrag(() => ({
        type: 'magnet',
        item: { data: props.data, sourceLoc: props.sourceLoc, coordinates: props.coordinates },
        collect: (monitor) => ({
            isDragging: !!monitor.isDragging(),
        })
    }));

    useEffect(() => {
        if (isDragging && props.openState !== undefined) props.setDrawerVisibility('hidden');
        else if (props.openState !== undefined) props.setDrawerVisibility('visible')
    }, [isDragging])


    function LightenDarkenColor(col, amt) {
        var usePound = true;
        if (col[0] === "#") {
            col = col.slice(1);
            usePound = true;
        }

        var num = parseInt(col, 16);

        var r = (num >> 16) + amt;

        if (r > 255) r = 255;
        else if (r < 0) r = 0;

        var b = ((num >> 8) & 0x00FF) + amt;

        if (b > 255) b = 255;
        else if (b < 0) b = 0;

        var g = (num & 0x0000FF) + amt;

        if (g > 255) g = 255;
        else if (g < 0) g = 0;
        var str = "000000" + (g | (b << 8) | (r << 16)).toString(16)
        return (usePound ? "#" : "") + str.substr(str.length - 6);
    }

    const magnetStyle = {
        cursor: isDragging ? 'grabbing' : 'grab',
        //cursor: 'grab',
        height: '100%',
        width: '100%',
        //minHeight: '100%',
        margin: 0,
        display: 'flex',
        //padding:'0',
        background: theme.fn.radialGradient(props.data.magnetColor, LightenDarkenColor(props.data.magnetColor.substr(1), -20)),
        justifyContent: 'center',
        borderRadius: '5px',
        boxShadow: '1px 1px 0px #F4AAB9',
        fontSize: '.7rem',
        color: props.data.textColor,
        fontWeight: 'bold',
        opacity: isDragging ? '.35' : '1',
    }
    const testStyle = {
        cursor: isDragging ? 'grabbing' : 'grab',
        opacity: isDragging ? '.35' : '1',
        color: props.data.textColor,
        background: theme.fn.radialGradient(props.data.magnetColor, LightenDarkenColor(props.data.magnetColor.substr(1), -20)),
        fontWeight: 'bold',
        borderRadius: '5px',
        height: '100%',
        position: 'relative',
        fontSize: '.7rem',
    }

    const handleOnDrag = () => {
        if (props.setModalTempHidden !== undefined) {
            props.setModalTempHidden(true);
        }
    }
    const iconMargin = 3;
    const iconSize = 10;
    return (
        <>
            <Flex
                style={testStyle}
                ref={drag}
                id={props.data.id}
                key={props.data.id}
                onDrag={() => handleOnDrag()}
                direction='row'
                wrap={false}
                align='center'
                justify='center'
            >
                <Text align='center'>
                    {props.data.magnetName.length >= 35 ? (
                        <Tooltip label={props.data.magnetName}><span>{props.data.magnetName.substring(0, 35)}...</span></Tooltip>
                    ) : props.data.magnetName}
                </Text>

                <TTC_Activity_Circle magnetData={props?.data} size={15} />

                {(props.data.magnetgroup?.canBeCloned && props.sourceLoc === 'pouch') && (
                    <>
                        {/* <Stack3 style={{ position: 'absolute', top: iconMargin, right: iconMargin }} size={iconSize} /> */}
                        <Stack3 style={{ position: 'absolute', top: iconMargin, left: iconMargin }} size={iconSize} />
                    </>
                )}
            </Flex>
            {/* <Grid className=''
                style={magnetStyle}
                ref={drag}
                id={props.data.id}
                key={props.data.id}
                onDrag={()=> handleOnDrag()}
                gutter={0}
                align='center'
                justify='center'
                grow
                >
                    
                    {props.data.magnetName.length >= 35 ? (
                    <Tooltip label={props.data.magnetName}><Grid.Col offset={2} span={8}><Text align='center'>{props.data.magnetName.substring(0, 35)}...</Text></Grid.Col></Tooltip>
                    ): (<Grid.Col  offset={2} span={8} style={{overflow: 'hidden'}}><Text align='center'>{props.data.magnetName}</Text></Grid.Col>)} 
                    {props.data.magnetgroup?.canBeCloned && props.sourceLoc === 'drawer' ? (
                    <Grid.Col style={{height: '100%'}} span={2}><Stack3 style={{position: 'relative', top:0, right: -3}} size={10}/></Grid.Col>):
                    (<Grid.Col span={2}></Grid.Col>)}
            </Grid> */}


            {/* <div className=''
                style={magnetStyle}
                ref={drag}
                id={props.data.id}
                key={props.data.id}
                onDrag={()=> handleOnDrag()}
                >
                    
                    {props.data.magnetName.length > 25 ? (
                    <Tooltip label={props.data.magnetName}><Center>{props.data.magnetName.substring(0, 24)}...</Center></Tooltip>
                    ): (<Center> {props.data.magnetName} </Center>)} 
                    {props.data.magnetgroup?.canBeCloned && (<Stack3 style={{marginLeft: 'auto'}} size={10}/>)}
            </div> */}
        </>
    );
}


export function MagnetForDisplayOnly(props) {
    const theme = useMantineTheme();
    function LightenDarkenColor(col, amt) {
        if (!col) return;
        var usePound = true;
        if (col[0] === "#") {
            col = col.slice(1);
            usePound = true;
        }

        var num = parseInt(col, 16);

        var r = (num >> 16) + amt;

        if (r > 255) r = 255;
        else if (r < 0) r = 0;

        var b = ((num >> 8) & 0x00FF) + amt;

        if (b > 255) b = 255;
        else if (b < 0) b = 0;

        var g = (num & 0x0000FF) + amt;

        if (g > 255) g = 255;
        else if (g < 0) g = 0;
        var str = "000000" + (g | (b << 8) | (r << 16)).toString(16)
        return (usePound ? "#" : "") + str.substr(str.length - 6);
    }

    const magnetStyle = {
        maxWidth: '200px',
        minHeight: '30px',
        background: theme.fn.radialGradient(props.data.magnetColor, LightenDarkenColor(props.data.magnetColor?.substr(1), -20)),
        color: props.data.textColor ? props.data.textColor : '',
        // padding: '0px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
    }

    return (
        <Button
            style={magnetStyle}
            size="md"
            shadow="xs"
            radius="md"
            fullWidth
        >
            <Center>
                <div
                    id={props.data.id}
                    key={props.data.id}
                >
                    {props.data.magnetName ? (props.data.magnetName) : props.defaultText ? props.defaultText : 'Sample Text'}
                </div>
            </Center>
            <TTC_Activity_Circle magnetData={props?.data} iconProps={{ margin: 3, size: 10 }} />
        </Button>

    );
}
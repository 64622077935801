import { Burger, Group, Header, Image, Title, useMantineTheme } from '@mantine/core'
import { useState } from 'react'
import useSettings from '../contexts/contextHooks/useSettings'
import logo from '../images/mb-full-trans.png'
import AdminHeaderMenu from '../Components/AdminConsole/AdminHeaderMenu'

const AdminHeader = () => {
    const theme = useMantineTheme();
    const { getAppearance, toggleNavbar, fullNavbar } = useSettings();
    const [opened, setOpened] = useState(false);

    return (
        <Header
            height={{ base: 50, md: 60 }} p="md"
            style={{ backgroundColor: getAppearance().header }}
        >
            <div style={{ height: '100%' }}>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <Burger
                        opened={fullNavbar}
                        onClick={() => toggleNavbar() && setOpened(o => !o)}
                        size="sm"
                        color={theme.colors.gray[6]}
                        mr="xl"
                    />
                    {/* </MediaQuery> */}
                    <Group style={{ position: 'fixed' }}>
                        <Image width={30} height={30} disabled src={logo} alt="Logo" />
                        <Title order={3} color={getAppearance().title}>Magniboard</Title>
                    </Group>
                    <AdminHeaderMenu />
                </div>
            </div>
        </Header >
    )
}

export default AdminHeader

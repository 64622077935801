import { useEffect, useState } from 'react';
import { Container, Title, LoadingOverlay } from '@mantine/core';
import ArchiveForm from './ArchiveForm';
import useSettings from '../../../contexts/contextHooks/useSettings';
import { closeModal, openModal } from '@mantine/modals';
import useTemplates from '../../../contexts/useTemplates';
import ArchiveNewForm from './ArchiveNewForm';
import { ArchiveObj } from './ArchiveStore';
import { observer } from 'mobx-react';
import { TableSort } from '../../Utilities/TableSort';

export const ArchiveList = observer(() => {
    const { getTemplates } = useTemplates();
    const [targetArchive, setTargetArchive] = useState();
    const [showForm, setShowForm] = useState(false);
    const [loading, setLoading] = useState(false);
    const [pageNumber, setPageNumber] = useState(1);
    const { fullNavbar } = useSettings();

    const handleSubmit = (archiveData) => {
        ArchiveObj.addArchive(archiveData);
        closeModal && closeModal('archiveForm');
    };

    const handleArchiveForm = async () => {
        const templateListData = await getTemplates();
        openModal({
            modalId: 'archiveForm',
            title: 'Create Archive',
            children: (
                <ArchiveNewForm handleSubmit={handleSubmit} templateListData={templateListData} ArchiveObj={ArchiveObj} />
            )
        })
    }

    const asyncGetRecord = async (id) => {
        setLoading(true);
        const recordData = await ArchiveObj.getArchiveById(id)
        setLoading(false);
        if (recordData) {
            setTargetArchive(recordData);
            setShowForm(true);
        }
    };

    const fetchArchives = async (page) => {
        setLoading(true);
        await ArchiveObj.getLimitedArchives(page ? page : 1);
        setLoading(false)
    };

    useEffect(() => {
        const getArchives = async () => { await fetchArchives(); }
        //record closed
        if (!showForm) getArchives(pageNumber);
    }, [showForm]);

    useEffect(() => {
        const getArchives = async () => { await fetchArchives(); }
        if (ArchiveObj?.archives?.length === 0) getArchives();
    }, []);

    return (
        <>
            {
                showForm ?
                    (<ArchiveForm setShowForm={setShowForm} archiveList={ArchiveObj?.archives?.records} setArchiveList={null} setTargetArchive={setTargetArchive} targetArchive={targetArchive} />)
                    :
                    <Container size="md" pr={{ xl: fullNavbar ? '300px' : null }} style={{
                        marginTop: '8vh'
                    }}>
                        <LoadingOverlay visible={loading} />
                        <Title align="center" order={3}>Archives</Title>
                        {(ArchiveObj.archives) && (
                            <TableSort
                                count={ArchiveObj?.archives?.count ? ArchiveObj?.archives?.count : null}
                                getData={fetchArchives}
                                tableLayout='auto'
                                initialSortBy='Date'
                                newButtonText='Create Archive'
                                reverseInitialSortOrder={true}
                                handleClickNew={handleArchiveForm}
                                handleClickItem={(id) => { asyncGetRecord(id); }}
                                changeSearchBar={<></>}
                                data={ArchiveObj?.archives && ArchiveObj?.archives?.records?.map((r, i) => {
                                    return {
                                        id: String(r.id),
                                        "Record Name": r?.title,
                                        "Date": {
                                            value: r?.timestamp,
                                            view: new Date(r?.timestamp).toLocaleString([], { dateStyle: 'medium', timeStyle: 'short' })
                                        },
                                        "Created By": r?.createdBy
                                    }
                                })}
                            />
                        )}
                    </Container>
            }
        </>
    )
});

import { Flex, Text, Tooltip } from "@mantine/core";
import { InfoCircle } from "tabler-icons-react";
import React from 'react';

const CustomToolTip = ({ text, children, position, icon }: { text: string, children?: React.ReactNode, position?: 'left' | 'right', icon?: React.ReactNode }) => {
    return (
        <Flex wrap='nowrap' align='center'>
            {position === 'left' && (
                <Tooltip label={text}>
                    <Text mr='sm'>
                        <InfoCircle size={15} />
                    </Text>
                </Tooltip>
            )}
            {children}
            {position !== 'left' && (
                <Tooltip label={text}>
                    <Text ml='sm'>
                        {icon === undefined ? (<InfoCircle size={15} />) : icon}
                    </Text>
                </Tooltip>
            )}
        </Flex>
    )
}
export default CustomToolTip;
import { Button, Center, Col, ColorInput, Container, FileButton, Grid, Group, Image, Input, Select, Text, TextInput } from "@mantine/core";
import React, { useEffect, useState } from "react";
import { MagnetForDisplayOnly } from "../../ViewComponents/MagnetComponent";
import { ArrowBackUp, CloudUpload, Trash } from "tabler-icons-react";
import { customErrorNotification } from "../../../exports/fetch/GlobalFetchSettings";

export const MagnetGenericTemplate = ({ form, colorSwatches, magnetGroupList }: any) => {
    const [file, setFile] = useState<File | null>(null);
    const maxSize = 500 * 1024; //500 kb

    useEffect(() => {
        if (file) {
            if (file.type !== 'image/jpeg' && file.type !== 'image/jpg' && file.type !== 'image/png') {
                setFile(null);
                customErrorNotification("Error", "Image must be in PNG, JPG, or JPEG format.");
            }
            else {
                if (file.size > maxSize) {
                    setFile(null);
                    customErrorNotification("Error", "File size exceeds the allowed limit of 500 KB");
                }
                else {
                    const reader = new FileReader();
                    reader.onload = (e) => {
                        const value = e.target?.result as string;
                        //"bandaid", dont know how to not include this bit everytime
                        form.setFieldValue("image_data", value.split(",")[1]);
                    };
                    reader.readAsDataURL(file);
                }
            }
        }
    }, [file]);

    return (
        <Container>
            <Grid gutter="xl">
                <Col span={6}>
                    <TextInput
                        size="md"
                        required
                        label="Magnet Text"
                        placeholder="name"
                        style={{ paddingTop: '10px' }}
                        {...form.getInputProps('magnetName')}
                    />
                    <ColorInput
                        format="hex"
                        swatches={colorSwatches}
                        label="Text Color"
                        {...form.getInputProps('textColor')}
                    />
                    <ColorInput
                        format="hex"
                        swatches={colorSwatches}
                        label="Magnet Color"
                        {...form.getInputProps('magnetColor')}
                    />
                    <Select
                        required
                        label="MagniGroup"
                        placeholder="Choose MagniGroup"
                        data={magnetGroupList?.map((group: { id: number; name: string; }) => ({
                            value: group.id,
                            label: group.name,
                        }))}
                        {...form.getInputProps('magnetGroupId')}
                    />
                    <Input.Wrapper
                        size="sm"
                        label="Magnet"
                    >
                        <Center><MagnetForDisplayOnly data={form.values} /></Center>
                    </Input.Wrapper>
                </Col>
                <Col span={5} offset={1}>
                    <Input.Wrapper
                        size="md"
                        style={{ paddingTop: '5px' }}
                        label="Image"
                    >
                        <Image width={'80%'} height={225}
                            src={`data:image/jpeg;base64,${form.values.image_data}`}
                            radius="md" alt="With default placeholder" withPlaceholder style={{ paddingTop: '5px' }}
                        />
                        <Group noWrap>
                            <FileButton onChange={setFile} accept="image/png,image/jpeg,image/jpg">
                                {(props) =>
                                    <Button
                                        leftIcon={<CloudUpload />}
                                        variant="outline"
                                        compact
                                        {...props}
                                    >
                                        Upload image
                                    </Button>}
                            </FileButton>
                            <Button color="red" compact onClick={() => form.setFieldValue("image_data", null)}><Trash /></Button>
                        </Group>
                        {file && (
                            <Text size="sm" ta="center" mt="sm">
                                File: {file.name}
                            </Text>
                        )}
                    </Input.Wrapper>
                </Col>
            </Grid>
        </Container>
    )
};
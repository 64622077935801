import { Title } from '@mantine/core'
import React from 'react'
import { Outlet } from 'react-router-dom'

function MagnetsLayout() {
  return (
    <>
    {/* <Title order={6} align='center'>Magnets</Title> */}
    <Outlet/>
    </>
  )
}

export default MagnetsLayout
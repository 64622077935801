import { useEffect, useState } from 'react';
import { Button, Group, Title, TransferList, TransferListData } from '@mantine/core';

const initialValues = [
  [
    { value: 'react', label: 'React' },
    { value: 'ng', label: 'Angular' },
    { value: 'next', label: 'Next.js' },
    { value: 'blitz', label: 'Blitz.js' },
    { value: 'gatsby', label: 'Gatsby.js' },
    { value: 'vue', label: 'Vue' },
    { value: 'jq', label: 'jQuery' },
  ],
  [
    { value: 'sv', label: 'Svelte' },
    { value: 'rw', label: 'Redwood' },
    { value: 'np', label: 'NumPy' },
    { value: 'dj', label: 'Django' },
    { value: 'fl', label: 'Flask' },
  ],
];

const arr1 = [
  { value: 'react', label: 'React' },
]

function TemplateMagnetGroupTransferList({availableList, usedList, setAvailableList, setUsedList, closeModal}) {
  const [combinedList, setCombinedList] = useState([]);

  useEffect(()=>{
    //let tempAvalList = [];
    //filter out used groups and map available groups
   
      let tempAvalList = availableList.map(avalGroup =>{
       return {...avalGroup, value: String(avalGroup.id), label: avalGroup.name}
      })
    
    //map used groups
    let tempUsedList = usedList.filter(g => g.id !== 1).map(usedGroup => { 
      return{...usedGroup, value: String(usedGroup.id), label:usedGroup.name}
    })
    setCombinedList([tempAvalList, tempUsedList])
  },[])

  return (<>
    {combinedList.length > 0 && (<>
      <TransferList
        value={combinedList}
        onChange={setCombinedList}
        searchPlaceholder="Search..."
        nothingFound="Nothing here"
        titles={['Available', 'Selected']}
        breakpoint="sm"
        showTransferAll={false}

      />
      <br/>
      <Group position='apart'>
        <Button color='gray.5'
          onClick={()=> closeModal()}
        >Discard</Button>
        <Button color='cyan'
          onClick={()=> {
            setAvailableList(combinedList[0]);
            setUsedList([...usedList.filter(g => g.id === 1), ...combinedList[1]]);
            closeModal();
          }}
        >Finish</Button>
      </Group>
    </>)}
  </>);
}

export default TemplateMagnetGroupTransferList
import { ActionIcon, Card, Center, Grid, Image, Input, LoadingOverlay, Text, TextInput, Tooltip } from "@mantine/core"
import React, { useEffect, useState } from "react"
import { ArrowRight, Copy, Magnet } from "tabler-icons-react";
import { showNotification } from "@mantine/notifications";
import { GetADPWorkerImage } from "../../../../exports/fetch/PluginFetch";

const WMPluginDisplay = ({ isADPLinked, workerData, form }: any) => {
    const [loading, setLoading] = useState(false);
    const [workerImage, setWorkerImage] = useState<any>(null);

    const copyWorkerNameToMagnetName = () => {
        form.setFieldValue('magnetName', workerData?.legalName?.formattedName);
        showNotification({
            title: 'Not Saved!',
            message: `Magnet Text was set to ${workerData?.legalName?.formattedName}. Click Submit to save changes!`
        });
    }

    const copyWorkerImageToMagnetImage = () => {
        form.setFieldValue('image_data', workerImage);
        showNotification({
            title: 'Not Saved!',
            message: `Magnet Image was set to ${workerData?.legalName?.formattedName}. Click Submit to save changes!`
        });
    }

    useEffect(() => {
        async function loadImage() {
            if (workerData?.associateOID) {
                try {
                    setLoading(true);
                    const image = await GetADPWorkerImage(workerData?.associateOID);
                    if (image !== "Not Found") setWorkerImage(image);
                } catch (error) {
                    console.error('Error fetching image data:', error);
                } finally {
                    setLoading(false);
                }
            }
        }
        loadImage();
    }, [workerData]);

    return (
        <>
            <Grid gutter="xs">
                <Grid.Col span={5}>
                    <TextInput
                        size="md"
                        disabled
                        label="Employee Name"
                        placeholder={workerData?.legalName?.formattedName}
                        style={{ paddingTop: '10px' }}
                        rightSection={
                            isADPLinked || form.values.linkNewMagnet ?
                                <Tooltip label="Copy to Magnet Text">
                                    <ActionIcon onClick={() => copyWorkerNameToMagnetName()} variant='filled' size="lg"><Copy size={30} /><ArrowRight size={20} /><Magnet size={30} /></ActionIcon>
                                </Tooltip>
                                : null}
                    />
                    <TextInput
                        size="md"
                        disabled
                        label="Department"
                        placeholder={workerData?.department?.shortName}
                        style={{ paddingTop: '10px' }}
                    />
                    <TextInput
                        size="md"
                        disabled
                        label="Worker ID"
                        placeholder={workerData?.workerID}
                        style={{ paddingTop: '10px' }}
                    />
                    <Text style={{ paddingTop: '10px' }} fw={600}>Employee Status:
                        {
                            workerData?.workerStatus === "Active" ?
                                <Text style={{ color: '#3bb143', display: 'inline' }}>&nbsp;Active</Text>
                                :
                                <Text style={{ color: '#FF0000', display: 'inline' }}>&nbsp;Inactive</Text>
                        }
                    </Text>
                </Grid.Col>
                <Grid.Col span={3} offset={2}>
                    <Input.Wrapper size="md" label="ADP Image" style={{ paddingTop: '10px' }}>
                        {(<Card>
                            <Card.Section>
                                <LoadingOverlay visible={loading} />
                                <Image
                                    src={workerImage && `data:image/jpeg;base64,${workerImage}`}
                                    radius="md"
                                    alt="ADP image not found"
                                    withPlaceholder
                                    caption={
                                        isADPLinked || form.values.linkNewMagnet ?
                                            <Center>
                                                <Tooltip label="Copy to Image">
                                                    <ActionIcon onClick={() => copyWorkerImageToMagnetImage()} variant='filled' size="lg"><Copy size={30} /><ArrowRight size={20} />
                                                        <Magnet size={30} />
                                                    </ActionIcon>
                                                </Tooltip>
                                            </Center>
                                            : null
                                    }
                                />
                            </Card.Section>
                        </Card>)}
                    </Input.Wrapper>
                </Grid.Col>
            </Grid >
        </>
    )
};

export default WMPluginDisplay;
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import reportWebVitals from './reportWebVitals';
import { MagnetProvider } from './contexts/MagnetContext';
import { MagnetGroupProvider } from './contexts/MagnetGroupContext';
import { SettingsProvider } from './contexts/SettingsContext';
import { PluginProvider } from './contexts/PluginContext';
import { ErrorBoundary } from 'react-error-boundary';
import { App } from './App';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <ErrorBoundary fallback={<center><div style={{ paddingTop: '20px' }}><b>Something went wrong!</b><br />Report this to the Magniboard developers and refresh your browser.</div></center>}>
    <React.StrictMode>
      <BrowserRouter>
        <SettingsProvider>
          <PluginProvider>
            <MagnetProvider>
              <MagnetGroupProvider>
                <Routes>
                  <Route path="/*" element={<App />} />
                </Routes>
              </MagnetGroupProvider>
            </MagnetProvider>
          </PluginProvider>
        </SettingsProvider>
      </BrowserRouter>
    </React.StrictMode>
  </ErrorBoundary>
);

reportWebVitals();

import { API_URL } from './GlobalFetchSettings.js'
import { privateFetch as fetch } from '../../hooks/usePrivateFetch.js';

const AUTH_URL = API_URL + '/Auth';

export async function LoginRequest(user: string, pwd: string) {
    try {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include',
            body: JSON.stringify({ username: user, password: pwd })
        };
        const data = await fetch(AUTH_URL, requestOptions);
        return data;
    } catch (e) {
        console.error(e)
    }
}

export async function LogoutRequest() {
    try {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include'
        };
        const data = await fetch(AUTH_URL + '/logout', requestOptions);
        return data;
    } catch (e) {
        console.error(e)
    }
}
import { showNotification } from "@mantine/notifications";
import { createContext, useState, useEffect, useRef } from "react";
import { PutMagnet, PostMagnet, PostMagnetFromBoard, GetMagnets, DeleteMagnet, GetMagnetsByGroupId } from "../exports/fetch/MagnetFetch";

const MagnetContext = createContext({});

export const MagnetProvider = ({ children }) => {
    const [magnetList, setMagnetList] = useState([]);
    const [loading, setLoading] = useState(true);
    const refreshInterval = useRef(null);

    const getMagnets = async () => {
        const data = await GetMagnets();
        setMagnetList(data);
        setLoading(false);
    }

    const getMagnetsByGroupId = async (groupId) => {
        const data = await GetMagnetsByGroupId(groupId);
        setMagnetList(data);
        setLoading(false);
    }

    const createMagnet = (magnet) => {
        PostMagnet(magnet)
            .then((data) => {
                setMagnetList(prevList => [...prevList, data])
                showNotification({
                    title: 'Create',
                    message: 'Magnet ' + magnet.magnetName + ' was created'
                });
            });
    }

    const createMagnetFromBoard = (magnet, templateId) => {
        PostMagnetFromBoard(magnet, templateId)
            .then((data) => {
                setMagnetList(prevList => [...prevList, data])
                showNotification({
                    title: 'Create',
                    message: 'Magnet ' + magnet.magnetName + ' was created'
                });
            });
    }

    const updateMagnet = (magnet) => {
        PutMagnet(magnet)
            .then((data) => {
                setMagnetList(prevList => prevList.map((m) => {
                    return m.id === magnet.id ? data : m;
                }));
                showNotification({
                    title: 'Update',
                    message: 'Magnet ' + magnet.magnetName + ' was updated'
                });
            })
    }
    const deleteMagnet = (magnet) => {
        DeleteMagnet(magnet.id)
            .then(() => {
                setMagnetList(prevList => [...prevList.filter(m => {
                    return m.id !== magnet.id
                })]);
                showNotification({
                    title: 'Delete',
                    message: 'Magnet ' + magnet.magnetName + ' was deleted'
                });
            });
    }

    const stopMagnetListRefresh = () => {
        clearInterval(refreshInterval.current);
    }

    const startMagnetListRefresh = async () => {
        setLoading(true);
        await getMagnets();
        refreshInterval.current = setInterval(async () => {
            await getMagnets();
        }, 10000);
    };

    const startMagnetListByGroupIdRefresh = async (groupId) => {
        setLoading(true);
        await getMagnetsByGroupId(groupId);
        refreshInterval.current = setInterval(async () => {
            await getMagnetsByGroupId(groupId);
        }, 10000);
    }

    useEffect(() => {
        return () => clearInterval(refreshInterval.current);
    }, []);

    return (
        <MagnetContext.Provider value={{
            magnetList,
            updateMagnet,
            createMagnet,
            deleteMagnet,
            getMagnets,
            loading,
            getMagnetsByGroupId,
            stopMagnetListRefresh,
            startMagnetListRefresh,
            startMagnetListByGroupIdRefresh,
            createMagnetFromBoard
        }}>
            {children}
        </MagnetContext.Provider>
    );
}

export default MagnetContext;
import { Routes, Route, Navigate } from 'react-router-dom';
import LoginPage from './pages/LoginPage';
import RequireAuth from './Components/RequireAuth';
import Unauthorized from './Components/Unauthorized';
import Missing from './pages/Missing';
import Layout from './Components/Layout';
import ActiveTemplateOptions from './Components/ViewComponents/ActiveTemplateOptions';
import TemplateList from './Components/AdminConsole/TemplateDesigner/TemplateList';
import TemplateDesigner from './Components/AdminConsole/TemplateDesigner/TemplateDesigner';
import MagnetList from './Components/AdminConsole/MagnetDesigner/MagnetList';
import MagnetForm from './Components/AdminConsole/MagnetDesigner/MagnetForm';
import UserList from './Components/AdminConsole/UserSettings/UserList';
import MagnetsLayout from './Components/AdminConsole/MagnetDesigner/MagnetsLayout';
import TemplateLayout from './Components/AdminConsole/TemplateDesigner/TemplateLayout';
import UserLayout from './Components/AdminConsole/UserSettings/UserLayout';
import MagnetGroupList from './Components/AdminConsole/MagnetDesigner/MagnetGroupList';
import MagnetGroupLayout from './Components/AdminConsole/MagnetDesigner/MagnetGroupLayout';
import AdminPageLayout from './pages/AdminPageLayout';
import { MantineProvider } from '@mantine/core';
import useSettings from './contexts/contextHooks/useSettings';
import RoleList from './Components/AdminConsole/RoleSettings/RoleList';
import RoleForm from './Components/AdminConsole/RoleSettings/RoleForm';
import { RoleStore, Role } from './Components/AdminConsole/RoleSettings/RoleStore';
import ArchiveScheduler from './Components/AdminConsole/ArchiveLogs/ArchiveScheduler';
import LandingPage from './Components/Site/LandingPage';
import PersistLogin from './Components/RouteUtilities/PersistLogin';
import { observer } from 'mobx-react';
import PluginList from './Components/AdminConsole/Plugins/PluginList';
import { NotificationsProvider } from '@mantine/notifications';
import { ModalsProvider } from '@mantine/modals';
import PluginConfig from './Components/AdminConsole/Plugins/PluginConfig';
import PluginLayout from './Components/AdminConsole/Plugins/PluginLayout';
import { ArchiveList } from './Components/AdminConsole/ArchiveLogs/ArchiveList';

export const App = observer(() => {
  const { getAppearance } = useSettings();

  return (
    <MantineProvider withGlobalStyles withNormalizeCSS theme={{
      colorScheme: getAppearance().colorScheme,
      colors: getAppearance().colors,
      primaryColor: getAppearance().primaryColor,

    }}>
      <NotificationsProvider zIndex={2077} position='bottom-right'>
        <ModalsProvider>
          <Routes>
            <Route path="/" element={<Layout />}>
              {/* public routes */}
              <Route path="/" element={<Navigate to="login" />} />
              {/* <Route path="home" element={<LandingPage />} /> */}
              <Route path="login" element={<LoginPage />} />
              {/* <Route path="unauthorized" element={<Unauthorized />} /> */}

              {/* magnet dnd pages */}
              <Route element={<RequireAuth />}
              >
                {/* front end routes */}
                <Route path='/boards' element={<ActiveTemplateOptions />}>
                  <Route path=':id' element={<ActiveTemplateOptions />} />
                </Route>
              </Route>

              {/* admin pages */}
              <Route element={<PersistLogin />}>
                <Route element={<RequireAuth />}
                >
                  {/* <Route path='/admin' element={<AdminPage />}> */}
                  <Route path='/admin' element={<AdminPageLayout />}>
                    {/* <Route index element={<Navigate to='boards' />} /> */}
                    <Route path="boards" element={<TemplateLayout />}>
                      <Route index element={<TemplateList />} />
                      <Route path="designer/new" element={<TemplateDesigner />} />
                      <Route path="designer/:id" element={<TemplateDesigner />} />
                    </Route>
                    <Route path="magnigroups" element={<MagnetGroupLayout />}>
                      <Route index element={<MagnetGroupList />} />
                    </Route>
                    <Route path="magnets" element={<MagnetsLayout />}>
                      <Route index element={<MagnetList />} />
                      <Route path="designer/new" element={<MagnetForm />} />
                      <Route path="designer/:id" element={<MagnetForm />} />
                    </Route>
                    <Route path="users" element={<UserLayout />}>
                      <Route index element={<UserList />} />
                    </Route>
                    <Route path="roles" element={<UserLayout />}>
                      <Route index element={<RoleList />} />
                      <Route path="new" element={<RoleForm />} />
                      <Route path=":id" element={<RoleForm />} />
                    </Route>
                    <Route path="archives" element={<ArchiveList />}>
                      <Route index element={<ArchiveList />} />
                    </Route>
                    <Route path="archivescheduler" element={<ArchiveScheduler />}>
                      <Route index element={<ArchiveScheduler />} />
                    </Route>
                    <Route path="plugins" element={<PluginLayout />}>
                      <Route index element={<PluginList />} />
                      <Route path=":id" element={<PluginConfig />} />
                    </Route>
                    {/* <Route path="integrations" element={<PluginList />}>
                      <Route index element={<PluginList />} />
                    </Route> */}
                  </Route>
                </Route>
              </Route>
              {/* catch-all routes */}
              <Route path="*" element={<Missing />} />

              {/*<Route path='/Error' element={<ErrorPage />} />*/}
            </Route>
          </Routes>
        </ModalsProvider>
      </NotificationsProvider>
    </MantineProvider>
  );
});
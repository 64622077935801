import { Tooltip } from "@mantine/core"
import React from "react"
import { ClockHour4 as Clock, ClockStop, AlertCircle } from "tabler-icons-react"

interface Activity_Props {
    magnetData: any
    iconProps: IconProps
}

interface IconProps {
    margin: number,
    size: number
}

export const FetchClockedIn = (clockedIn: boolean, iconProps: IconProps) => {
    if (clockedIn === true) {
        return (
            <div>
                <Clock style={{ position: 'absolute', top: iconProps.margin, right: iconProps.margin }} strokeWidth={3.5} color={'#40bf47'}
                    size={iconProps.size} />
            </div>
        );
    } else {
        return (
            <div>
                <ClockStop style={{ position: 'absolute', top: iconProps.margin, right: iconProps.margin }} strokeWidth={2} color={'#ff0000'}
                    size={iconProps.size} />
            </div>
        );
    }
};

export const FetchExceptions = (exceptionCount: number, iconProps: IconProps) => {
    return (
        <div>
            <AlertCircle style={{ position: 'absolute', bottom: iconProps.margin, right: iconProps.margin }} strokeWidth={2} color={'#FFFF00'} size={iconProps.size} />
        </div>
    );
};


export const TTC_Activity_Circle = ({ magnetData, iconProps }: Activity_Props) => {
    let flattenedData = {
        clockedIn: magnetData?.pluginxmagnet?.clocked_in,
        startTime: magnetData?.pluginxmagnet?.stored_object?.startTime?.toLocaleString(),
        endTime: magnetData?.pluginxmagnet?.stored_object?.endTime?.toLocaleString(),
        exceptions: magnetData?.pluginxmagnet?.stored_object?.exceptions,
        exceptionCount: magnetData?.pluginxmagnet?.stored_object?.exceptions?.length
    }

    // const FetchClockedIn = forwardRef<HTMLDivElement>((props, ref) => {
    //     if (flattenedData.clockedIn === true) {
    //         return (
    //             <div ref={ref} {...props}>
    //                 <Clock style={{ position: 'absolute', top: iconProps.margin, right: iconProps.margin }} strokeWidth={3.5} color={'#40bf47'}
    //                     size={iconProps.size} />
    //             </div>)
    //     }
    //     else {
    //         return (
    //             <div ref={ref} {...props}>
    //                 <ClockStop style={{ position: 'absolute', top: iconProps.margin, right: iconProps.margin }} strokeWidth={2} color={'#ff0000'}
    //                     size={iconProps.size} />
    //             </div>)

    //     }
    // });

    // const FetchExceptions = forwardRef<HTMLDivElement>((props, ref) => (
    //     <div ref={ref} {...props}>
    //         <AlertCircle style={{ position: 'absolute', bottom: iconProps.margin, right: iconProps.margin }} strokeWidth={2} color={'#FFFF00'} size={iconProps.size} />
    //     </div>
    // ));

    // function forwardRefToolTips() {
    //     return (
    //         <>
    //             <Tooltip label={`Clocked In: ${flattenedData?.clockedIn}`} position="left" withArrow>
    //                 <FetchClockedIn />
    //             </Tooltip>
    //             {flattenedData?.exceptions ?
    //                 <Tooltip label={`${flattenedData?.exceptionCount} - Exception(s)`} position="left" withArrow>
    //                     <FetchExceptions />
    //                 </Tooltip>
    //                 : null
    //             }
    //         </>
    //     );
    // }

    return (
        <>
            {/* {magnetData?.pluginxmagnet ? forwardRefToolTips() : null} */}
            {magnetData?.pluginxmagnet ? FetchClockedIn(flattenedData?.clockedIn, iconProps) : null}
            {flattenedData?.exceptions ? FetchExceptions(flattenedData?.exceptionCount, iconProps) : null}
        </>
    )
}
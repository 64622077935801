import { action, makeAutoObservable, computed, makeObservable, observable, toJS } from "mobx"
import { GetADPWorkerByAOID, GetADPWorkerImage, GetADPWorkersPlugin, GetPlugins, LinkPluginXMagnet, UnlinkPluginXMagnet } from "../../../exports/fetch/PluginFetch";

export class PluginStoreClass {
    plugins: PluginClass[] = [];

    constructor() {
        makeObservable(this, {
            plugins: observable,
            getPlugins: action,
            getADPWorkersByPluginId: action,
            fetchADPWorkerByAOID: action,
            fetchADPWorkerImage: action,
            linkMagnet: action,
            deletePluginXMagnet: action,
        })
    }

    async getPlugins() { this.plugins = await GetPlugins(); }

    async getADPWorkersByPluginId(id: number) {
        let plugin = new PluginClass();
        let adpWorkerPlugin = await GetADPWorkersPlugin(String(id));

        plugin.id = id;
        plugin.name = adpWorkerPlugin?.name;
        plugin.isActive = adpWorkerPlugin?.isActive;
        plugin.interval = adpWorkerPlugin?.interval;
        plugin.image = adpWorkerPlugin?.image;
        plugin.stored_object = adpWorkerPlugin?.workers;

        return plugin;
    }

    async fetchADPWorkerByAOID(id: string) {
        const worker = await GetADPWorkerByAOID(id);
        return worker;
    }

    async fetchADPWorkerImage(id: string) {
        const image = await GetADPWorkerImage(id);
        return image;
    }

    async linkMagnet(body: any) {
        const linkPluginXMagnet = await LinkPluginXMagnet(body);
        return linkPluginXMagnet;
    }

    async deletePluginXMagnet(id: number) {
        const deletePXM = await UnlinkPluginXMagnet(id);
        return deletePXM;
    }
}

export const PluginStore = new PluginStoreClass();

export class PluginClass {
    id?: number
    name?: string
    isActive?: boolean
    interval?: string
    image?: string
    customCSS?: PluginCustomCSS
    stored_object?: any[]
    workers?: any;
    constructor() {
        makeObservable(this, {
            id: false
            // addBoard: action,
        })
    }
}

interface PluginCustomCSS {
    title: string,
    description: string,
    image: string
}
import React from 'react'
import { Outlet } from 'react-router-dom'

function TemplateLayout() {
  return (
    <>
    <Outlet/>
        </>
  )
}

export default TemplateLayout
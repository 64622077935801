import { Title, Paper, TextInput, Stack, Group, Button, Container, PasswordInput, Select, Text, UnstyledButton, Tooltip, Center, Space, Collapse, Checkbox } from '@mantine/core';
import { useForm } from '@mantine/form';
import { useEffect, useState } from 'react';
import { DeviceFloppy, Lock, Plus, Trash } from 'tabler-icons-react';
import { NewUser, GetUserById, UpdateUser } from '../../../exports/fetch/UserFetch';
import { RoleStore } from '../RoleSettings/RoleStore';
import { AuthStore } from '../../../stores/authStore';
import { userClassObj } from './UserStore';

const NewUserForm = ({ confirmDelete, onSubmit, userId }) => {
    const form = useForm({
        initialValues: {
            username: '',
            changepassword: false,
            password: '',
            confirmPassword: '',
            role_id: '',
        },
        validate: {
            role_id: (value) => (value === null ? 'Choose a role' : null),
            username: (value) => (value.length < 5 ? 'Username must be at least 5 characters long' : null),
            password: (value, values) => (values.changepassword && value.length < 6 ? 'Password must be at least 6 characters long' : null),
            confirmPassword: (value, values) => (values.changepassword && value != form.values.password ? 'Passwords don\'t match' : null),
        },
    });
    let selectRolesArray = RoleStore.rolesForSelectList.filter((i) => i.value !== 1995);
    useEffect(() => {
        if (userId !== undefined) {
            GetUserById(userId).then((data) => {
                form.setValues({
                    id: data.id,
                    username: data.username,
                    role_id: data.role_id,
                })
            })
        }
        RoleStore.getRoles();
    }, [])

    const handleSubmit = async () => {
        if (!!userId) {
            try {
                //await UpdateUser(userId, form.values);
                userClassObj.updateUser(userId, form.values);
            } catch (err) {
                console.error(err);
            }

        }
        else {
            try {
                userClassObj.addUser(form.values);
            } catch (ex) {
                console.error(ex);
            }
        }
        onSubmit && onSubmit('userForm');
    };

    return (
        <>
            <Container size="sm">
                <Paper shadow="xl" p="lg">
                    {userId !== null && (
                        <Group
                            position='left'
                            style={{ position: 'absolute', top: '25px', left: '10px' }}
                        >
                            {userId ? userId !== 1 && AuthStore.username !== form.values.username ?
                                (<Trash size={20} style={{ cursor: 'pointer' }} color='red' onClick={() => confirmDelete(userId, form.values.username)} />)
                                :
                                (<Tooltip label="System Default - Cannot Delete"><UnstyledButton><Lock size={20} color='gray' /></UnstyledButton></Tooltip>)
                                :
                                null
                            }
                        </Group>
                    )}
                    <Stack>
                        <Title order={3} align="center">{userId ? 'Edit User' : 'New User'}</Title>
                        <form onSubmit={form.onSubmit((values) => handleSubmit(values))} autoComplete='off'>
                            <TextInput
                                size="md"
                                required
                                label="Username"
                                disabled={!!userId}
                                {...form.getInputProps('username')}
                            />
                            {userId !== 1 && (
                                <Select
                                    required
                                    label="User Role"
                                    placeholder="Choose a role"
                                    data={selectRolesArray}
                                    {...form.getInputProps('role_id')}
                                />
                            )}
                            {
                                userId ?
                                    <Checkbox mt='lg' label='Update Password?'
                                        {...form.getInputProps('changepassword')}
                                    />
                                    : null
                            }
                            {
                                userId ?
                                    <Collapse in={form.values.changepassword}>
                                        <PasswordInput
                                            size="md"

                                            label="Password"
                                            {...form.getInputProps('password')}
                                        />
                                        <PasswordInput
                                            size="md"

                                            label="Confirm Password"
                                            {...form.getInputProps('confirmPassword')}
                                        />
                                    </Collapse>
                                    :
                                    <>
                                        <PasswordInput
                                            size="md"
                                            required
                                            label="Password"
                                            {...form.getInputProps('password')}
                                        />
                                        <PasswordInput
                                            size="md"
                                            required
                                            label="Confirm Password"
                                            {...form.getInputProps('confirmPassword')}
                                        />
                                    </>
                            }
                            <Group position="right" mt="md">
                                <Button type="submit" leftIcon={userId ? (<DeviceFloppy />) : (<Plus />)}>{userId ? 'Save' : 'Create'}</Button>
                            </Group>
                        </form>
                    </Stack>
                </Paper>
            </Container>
        </>)

}

export default NewUserForm
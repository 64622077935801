import { useState, useEffect, useRef } from "react";
import { Table, Button, TextInput, Checkbox, Group, useMantineTheme } from '@mantine/core';
import useTemplateDesigner from "../../../contexts/useTemplateDesigner";
import { Minus, Plus } from "tabler-icons-react";
import { getHotkeyHandler } from "@mantine/hooks";
import useTemplates from "../../../contexts/useTemplates";
import ArchiveMagnet from "../ArchiveLogs/ArchiveMagnet";

const TemplateDesignerGrid = (props) => {

    const theme = useMantineTheme();
    const [showInputForFocusedCell, setShowInputForFocusedCell] = useState({ x: null, y: null });
    const {
        templateDesign,
        globalSettings,
        focusedCell,
        updateFocusedCell,
        handleCellDataOnChange,
        handleAddRow,
        handleRemoveRow,
        handleAddColumn,
        handleRemoveColumn
    } = useTemplateDesigner();

    const { loadingTemplate } = useTemplates();

    const focusRef = useRef(null);

    useEffect(() => {
        if (globalSettings.showTextInputs) focusRef?.current?.focus();
        //when focused cell changes, reset show input
        if (focusedCell.x === showInputForFocusedCell.x && focusedCell.y === showInputForFocusedCell.y) return;
        setShowInputForFocusedCell({ x: null, y: null });

    }, [focusedCell])

    const handleEnterKey = () => {
        if (focusedCell.y >= templateDesign.rows.length - 1) return;
        updateFocusedCell(prevState => ({ ...prevState, cell: templateDesign.rows[prevState.y + 1].cells[prevState.x], y: prevState.y + 1 }));
        setShowInputForFocusedCell(prevState => ({ ...prevState, y: prevState.y + 1 }));
    }
    const handleTabKey = () => {
        if (focusedCell.x >= templateDesign.rows[0].cells.length - 1) {
            if (focusedCell.y >= templateDesign.rows.length - 1) return;
            updateFocusedCell(prevState => ({ ...prevState, cell: templateDesign.rows[prevState.y + 1].cells[0], y: prevState.y + 1, x: 0 }));
            setShowInputForFocusedCell(prevState => ({ ...prevState, y: prevState.y + 1, x: 0 }));

        }
        else {
            updateFocusedCell(prevState => ({ ...prevState, cell: templateDesign.rows[prevState.y].cells[prevState.x + 1], x: prevState.x + 1 }));
            setShowInputForFocusedCell(prevState => ({ ...prevState, x: prevState.x + 1 }));
        }

    }

    const rowHeight = String(90 / templateDesign?.rows?.length) < 7 ? String(80 / templateDesign?.rows?.length) + 'vh' : '7vh'
    return (<>
        {/* <LoadingOverlay visible={loadingTemplate} /> */}
        <Table
            sx={(theme) => ({
                [theme.fn.smallerThan('sm')]: { minWidth: '90%' },
            })}
            color={''}
            style={{
                maxWidth: templateDesign?.rows[0]?.cells?.length * 10 > 98 ? '98%' : String(templateDesign?.rows[0].cells.length * 10) + '%',
                minWidth: '60%',
                margin: 'auto',
                padding: '10px',
                border: theme.colorScheme == 'dark' ? '1px solid hsl(200, 100%, 10%)' : '1px solid  hsl(200, 100%, 1%)',
                fontSize: '10px',
                maxHeight: 'calc(100vh-100px)'
            }}>
            {templateDesign.showTemplateHeader && (
                <thead><tr><th colSpan={templateDesign.rows[0]?.cells?.length}>{templateDesign.templateHeader}</th></tr></thead>
            )}
            <tbody>
                {templateDesign !== undefined && templateDesign.rows?.map((row, yIndex) => {
                    return (<tr key={yIndex}>
                        {row.cells.map((cell, xIndex) => {
                            const CellTag = cell.header ? `th` : `td`;

                            if (cell.colSpan > 0)
                                return (
                                    <CellTag
                                        onClick={(e) => updateFocusedCell({ cell: cell, x: xIndex, y: yIndex })}
                                        onDoubleClick={() => setShowInputForFocusedCell({ x: xIndex, y: yIndex })}
                                        colSpan={cell.colSpan}
                                        key={xIndex}
                                        style={{
                                            backgroundColor: cell.droppable ? (theme.colorScheme == 'dark' ? 'hsl(200, 100%, 6%)' : 'cyan') : null,
                                            fontWeight: cell.header && 'bold',
                                            border: (focusedCell.x === xIndex && focusedCell.y === yIndex) ? '3px solid #CD5C5C' : theme.colorScheme == 'dark' ? '1px solid hsl(200, 100%, 10%)' : '1px solid  hsl(200, 100%, 1%)',
                                            height: 'calc(' + rowHeight + ')',
                                            padding: 1,
                                            width: String(100 / row.cells.length) + '%'
                                        }}
                                    >
                                        {
                                            globalSettings.showMagnets && cell?.magnet ?
                                                <ArchiveMagnet setModalOpened={() => { }} sourceLoc="board" key={xIndex + ',' + yIndex} coordinates={{ x: xIndex, y: yIndex }} data={cell.magnet} />
                                                :
                                                null
                                        }
                                        {(globalSettings.showTextInputs || (showInputForFocusedCell.x === xIndex && showInputForFocusedCell.y === yIndex)) ? (
                                            <TextInput
                                                autoFocus={(showInputForFocusedCell.x === xIndex && showInputForFocusedCell.y === yIndex) ? true : false}
                                                ref={(focusedCell.x === xIndex && focusedCell.y === yIndex) ? focusRef : null}
                                                type="text"
                                                placeholder="Cell Text"
                                                value={cell.text}
                                                onChange={(e) => handleCellDataOnChange({ cell: { ...cell, text: e.target.value }, x: xIndex, y: yIndex })}
                                                onKeyDown={getHotkeyHandler([['Enter', handleEnterKey], ['Tab', handleTabKey]])}
                                            />
                                        ) : <div>{cell.text ? cell.text : <center>
                                            {/* {cell.rowId} */}
                                        </center>}</div>
                                        }

                                        {globalSettings.showDroppableCheckBoxes && (
                                            <span><Checkbox
                                                style={{ fontWeight: 'normal', fontSize: 'small', float: 'left' }}
                                                label='Droppable'
                                                key={xIndex}
                                                checked={cell.droppable}
                                                onChange={(e) => handleCellDataOnChange({ cell: { ...cell, droppable: e.target.checked }, x: xIndex, y: yIndex })}
                                            /></span>
                                        )}
                                        {globalSettings.showHeaderCheckBoxes && (
                                            <span><Checkbox
                                                style={{ fontWeight: 'normal', fontSize: 'small', float: 'right' }}
                                                label='Header Cell'
                                                key={xIndex}
                                                checked={cell.header}
                                                onChange={(e) => handleCellDataOnChange({ cell: { ...cell, header: e.target.checked }, x: xIndex, y: yIndex })}
                                            /></span>
                                        )}
                                    </CellTag>
                                )
                            else return null;
                        })}
                        {yIndex === 0 && (
                            <td style={{ padding: '0px 5px 0px 0px' }} rowSpan={templateDesign.rows.length}>
                                <Group position='center' spacing={1}>
                                    <Button compact color='red.4' style={{ borderRadius: '0px 25px 25px 0px' }} fullWidth onClick={(e) => handleRemoveColumn(e)}><Minus color='black' strokeWidth={2} size={15} /></Button>
                                    <Button compact color='green.3' style={{ borderRadius: '0px 25px 25px 0px' }} fullWidth onClick={(e) => handleAddColumn(e)}><Plus color='black' strokeWidth={2} size={15} /></Button>
                                </Group>
                            </td>
                        )}
                    </tr>)
                })}
                <tr><td style={{ padding: '0px 0px 5px 0px' }} colSpan={templateDesign.rows[0].cells.length}>
                    <Group position='center' spacing={1} p={0}>
                        <Button compact color='red.4' style={{ borderRadius: '0px 0px 25px 25px' }} onClick={(e) => handleRemoveRow(e)}><Minus color='black' strokeWidth={2} size={15} /></Button>
                        <Button compact color='green.3' style={{ borderRadius: '0px 0px 25px 25px' }} onClick={(e) => handleAddRow(e)}><Plus color='black' strokeWidth={2} size={15} /></Button>
                    </Group>
                </td></tr>
            </tbody>
        </Table>
    </>);
}

export default TemplateDesignerGrid;
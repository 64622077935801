import { action, makeObservable, observable } from "mobx";
import { DeleteUser, GetUsersFetch, NewUser, UpdateUser } from "../../../exports/fetch/UserFetch";



class UserStoreClass {
    users: UserClass[] = [];
    constructor() {
        makeObservable(this, {
            users: observable,
            getUsers: action,
            addUser: action,
            updateUser: action,
            deleteUser: action
        });
    }
    getUsers() {
        GetUsersFetch().then((data) => {
            this.users = data;
        })
    }
    setUsers(users: UserClass[]) {
        this.users = users;
    }
    addUser(userData: UserClass) {
        NewUser(userData).then((data) => {
            if (!data) return;
            this.users.push(data);
        })
    }
    updateUser(id: number, userData: UserClass) {
        UpdateUser(id, userData).then((data) => {
            if (!data) return;
            this.users = this.users.map(u => String(u.id) === String(userData.id) ? userData : u);
        })
    }
    deleteUser(id: number) {
        DeleteUser(id).then((data) => {
            if (!data) return;
            this.users = this.users.filter(r => String(r.id) !== String(id));
        });
    }
}

export const userClassObj = new UserStoreClass();

class UserClass {
    id?: number;
    username?: string;
    changepassword?: boolean;
    password?: string;
    confirmPassword?: string;
    role_id?: number;
    constructor() {
        makeObservable(this, {
            id: false,
            username: observable,
            changepassword: false,
            password: false,
            confirmPassword: false,
            role_id: observable
        })
    }
}
import { Button, Checkbox, Container, Group, Paper, Stack, TextInput, Title } from '@mantine/core'
import { useForm } from '@mantine/form';
import React from 'react'

const TemplateDuplicateForm = ({ templateDesign, handleSubmit }: any) => {
  const form = useForm({
    initialValues: {
      templateName: templateDesign.templateName + ' - clone',
      templateData: templateDesign,
      templateHasMagnets: false
    },
    validate: {
      templateName: (value) => ((value?.length < 1) ? 'Board name cannot be left empty!' : null),
    },
  });
  return (
    <>
      <Container size="sm">
        <Title order={3} align="center">Duplicate Template: <i>{templateDesign?.templateName}</i></Title>
        <Paper shadow="xl" p="lg">
          <form onSubmit={form.onSubmit((values) => handleSubmit(values))} autoComplete='off'>
            <Stack>
              <TextInput
                required
                size='xs'
                label='New Board Name'
                placeholder={form.values.templateName}
                {...form.getInputProps('templateName')}
              />
              <Checkbox
                checked={form.values.templateHasMagnets}
                label="Include previous magnets on board"
                {...form.getInputProps('templateHasMagnets')}
              />
              <p><i>Note: This operation may take a minute or two for larger boards.</i></p>
              <Group position="right" mt="md">
                <Button type="submit">Duplicate Board</Button>
              </Group>
            </Stack>
          </form>
        </Paper>
      </Container>
    </>)
}

export default TemplateDuplicateForm

import { Magnet } from './MagnetComponent';
import DroppableCell from './DroppableCellComponent.js';
import { Table, Button, useMantineTheme, Center, Group, Grid, Text } from '@mantine/core';
import { ChevronRight, ChevronsRight } from 'tabler-icons-react';

function Pouch(props) {
    const theme = useMantineTheme();
    const dropZoneStyle = {
        //border: '1px solid black',
        height: 'calc(' + props.rowHeight + ')',
        padding: 1,
        //width: String(100 / 12) + '%'


    }
    const pouchButtonStyle = {
        //border: '1px solid black',
        height: 'calc(' + props.rowHeight + ')',
        padding: 1,
        //width: String(100 / 12) + '%'
        width: '200px',

    }
    let count = 0;
    return (
        <>
            <Grid grow gutter={0}>
                <Grid.Col span={10}>
                    <DroppableCell style={dropZoneStyle} bgColor='#FFC9C9' destinationLoc="pouch" moveMagnet={props.moveMagnet}>Dropping in pouch...</DroppableCell>
                </Grid.Col>
                <Grid.Col span={2}>
                    <Button
                        fullWidth
                        variant="outline"
                        size="sm"
                        color={theme.colorScheme === 'dark' ? 'gray.0' : 'dark'}
                        onClick={() => props.openState.setSideBarOpen(true)}
                    >
                        MagniPouch
                    </Button>
                </Grid.Col>
            </Grid>
        </>
        // <div className="Pouch">
        //      <Table 
        //         withBorder={false}
        //         style={{
        //             margin: 'auto',
        //             padding: '10px',
        //             //border: '1px solid black',
        //             fontSize: '10px',
        //         }}>
        //         <tbody>
        //             <tr>
        //             <td key='permanent drop' style={dropZoneStyle}><DroppableCell style={dropZoneStyle} bgColor='#FFC9C9' destinationLoc="pouch" moveMagnet={props.moveMagnet}>Dropping in pouch...</DroppableCell></td>
        //                 <th style={pouchButtonStyle}>
        //                     <Button
        //                         fullWidth
        //                         variant="outline"
        //                         size="md"
        //                         color="dark"
        //                         onClick={() => props.openState.setSideBarOpen(true)}
        //                     >
        //                         MagniPouch
        //                     </Button>
        //                 </th>

        //             </tr>
        //         </tbody>
        //     </Table>
        // </div>

    );
}

export default Pouch;
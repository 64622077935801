import { useEffect, useState } from 'react';
import BoardComponent from './BoardComponent.js'
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { TouchBackend } from 'react-dnd-touch-backend'
import { getActiveBoardWithPouchById } from '../../exports/fetch/TemplateFetch.js';
import { LoadingOverlay } from '@mantine/core';
import { useNavigate } from 'react-router';

const TemplateSelectedView = ({ id, setActiveTab, setActiveTemplates }) => {
    const [viewData, setViewData] = useState();
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const activeBoardWithPouch = async () => {
            setLoading(true);
            let data = await getActiveBoardWithPouchById(id);
            if (!data) navigate('/boards');
            setViewData(data);
            setLoading(false);
        }
        activeBoardWithPouch();
    }, []);

    const opts = {
        enableMouseEvents: true
    }

    return (
        <div>
            <LoadingOverlay visible={loading} />
            <div className="App">
                <DndProvider backend={TouchBackend} options={opts}>
                    {viewData !== undefined && (<BoardComponent viewData={viewData} setViewData={setViewData} setActiveTab={setActiveTab} setActiveTemplates={setActiveTemplates} />)}
                </DndProvider>
            </div>
        </div>
    )
}

export default TemplateSelectedView

import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react';
import { Badge, Button, Card, Center, Container, Group, Image, LoadingOverlay, SimpleGrid, Text, Title } from '@mantine/core';
import useSettings from '../../../contexts/contextHooks/useSettings';
import { PluginInterface } from '../../../contexts/PluginContext';
import { PluginClass, PluginStore } from './PluginStore';
import { Settings } from 'tabler-icons-react';
import { useNavigate } from 'react-router-dom';
import { toJS } from 'mobx';

interface PluginListProps {
}
const PluginList = ({ }: PluginListProps) => {
    const { fullNavbar }: { fullNavbar?: boolean } = useSettings();
    const navigate = useNavigate();

    useEffect(() => {
        PluginStore.getPlugins();
    }, [])

    return (
        <>
            <Container size="xl" pr={{ xl: fullNavbar ? '300px' : undefined }} style={{ marginTop: '8vh' }}>
                {/* <LoadingOverlay visible={loading} /> */}
                <Title align="center" order={3}>Plugin Library</Title>
                <SimpleGrid cols={2} style={{ paddingTop: '15px' }}>
                    {
                        PluginStore.plugins.map((plugin: PluginClass, i: number) => {
                            // const image = plugin?.details?.image;
                            return (
                                <Card key={plugin.id} shadow="xs" p="md" radius="md" withBorder>
                                    <Card.Section component="a">
                                        {
                                            plugin?.customCSS?.image ?
                                                <Image
                                                    src={require(`../../../images/plugins/${plugin?.customCSS?.image}`)}
                                                    height={160}
                                                    alt={plugin.name}
                                                />
                                                :
                                                <Image
                                                    src="https://images.unsplash.com/photo-1527004013197-933c4bb611b3?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=720&q=80"
                                                    height={160}
                                                    alt={plugin.name}
                                                />
                                        }
                                    </Card.Section>
                                    {plugin.isActive ? (
                                        <>
                                            <Group position="apart" mt="md" mb="xs">
                                                <Text fw={500}>{plugin?.customCSS?.title}</Text>
                                                <Badge size="xs" color="green" variant="light">Subscription Active</Badge>
                                            </Group>
                                            <Center>
                                                <Button disabled={plugin.id != 1000} rightIcon={<Settings size={15} />} onClick={() => navigate(String(plugin.id))}> Configure</Button>
                                            </Center>
                                        </>
                                    )

                                        : (<>
                                            <Badge color="pink" variant="light">Available</Badge>
                                            <Button variant="light" color="blue" fullWidth mt="md" size="xs" radius="md">Contact us to get started!</Button>
                                        </>)}
                                </Card>
                            )
                        })
                    }
                </SimpleGrid>
            </Container>
        </>
    )
}

export default observer(PluginList);

import { API_URL, customErrorNotification } from './GlobalFetchSettings.js'
import { privateFetch as fetch } from '../../hooks/usePrivateFetch.js';
import { AuthStore } from '../../stores/authStore.ts';

const BOARD_URL = API_URL + '/Boards';
const USER_URL = API_URL + '/User';

export const GetUsersFetch = async () => {
    try {
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + AuthStore.accessToken
            }
        };
        const res = await fetch(USER_URL + '/GetUsers', requestOptions);
        if (!res.ok) {
            customErrorNotification(res);
            return false;
        }
        return res.json();
    }
    catch (e) {
        console.error(e)
    }
};

export const GetUserById = async (id) => {
    try {
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + AuthStore.accessToken
            }
        };
        const response = await fetch(USER_URL + '/GetUsers/' + id, requestOptions);
        return response.json();
    }
    catch (e) {
        console.error(e)
    }
};

export const UpdateUser = async (id, body) => {
    try {
        const requestOptions = {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + AuthStore.accessToken
            },
            body: JSON.stringify(body)
        };
        const res = await fetch(USER_URL + '/UpdateUser/' + id, requestOptions);
        if (res.status === 409) {
            customErrorNotification(res, 'Username already exists.')
            throw res;
        }
        else if (!res.ok) {
            customErrorNotification(res);
            throw res;
        }
        return res.json();
    }
    catch (e) {
        console.error("ERROR: " + e)
    }
};

export const DeleteUser = async (id) => {
    try {
        const requestOptions = {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + AuthStore.accessToken
            }
        };
        let res = await fetch(USER_URL + '/' + id, requestOptions)
        if (res.ok) return true;
        else if (res.status === 403) customErrorNotification(res, 'Cannot delete yourself or master user.')
        else if (res.status === 404) customErrorNotification(res, 'Could not find User to delete.')
        else customErrorNotification(res);
        return false;
    }
    catch (e) {
        console.error("ERROR: " + e)
    }
};

export const NewUser = async (body) => {
    try {
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + AuthStore.accessToken
            },
            body: JSON.stringify(body)
        };
        const res = await fetch(USER_URL + '/NewUser', requestOptions);
        if (res.ok) return res.json();
        else if (res.status === 409) customErrorNotification(res, 'Username already exists')
        else customErrorNotification(res);
        return false;
    }
    catch (e) {
        console.error("ERROR: " + e)
    }
};


import { createContext, useState, useEffect } from "react";
// import { useNavigate } from "react-router-dom";
import { API_URL } from "../exports/fetch/GlobalFetchSettings";

const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
    const [token, setToken] = useState(null);
    const [roles, setRoles] = useState([]);
    const [roleData, setRoleData] = useState();
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(false);
    const [allowedPages, setAllowedPages] = useState([]);
    // const navigate = useNavigate();

    useEffect(() => {
        if (!roles) {
            getMe();
        }
    }, [token])

    const getToken = () => {
        if (!token) {
            const sessionToken = window.sessionStorage.getItem("mbtoken");
            setToken(sessionToken);
            return sessionToken;
        }

        return token
    }
    //logging in
    const createAuth = (username, accessToken, role) => {
        setToken(accessToken);
        setRoleData(role);
        setUser(username);
        buildAllowedPagesArray(role);

        window.sessionStorage.setItem("mbtoken", accessToken);
        window.sessionStorage.setItem("mbuser", username);

    }

    const getUser = () => {

        let userData
        if (!user) {
            userData = window.sessionStorage.getItem("mbuser");
        }
        else userData = user;

        return userData;
    }


    const logout = () => {
        window.sessionStorage.clear();
        // setRoles([]);
        setToken(null);
        setUser(null);
    }

    const getMeFetch = async () => {
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            }
        };
        const response = await fetch(API_URL + '/Auth/GetMe', requestOptions);
        return await response.json();
    }

    const getMe = async () => {
        const data = await getMeFetch();
        setRoleData(data.user.roles);
        setUser(data.username);
    }

    const getRoleData = () => {
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            }
        };
        fetch(API_URL + '/User/GetRolePermissions', requestOptions).then((res) => res.json().then(data => {
            setRoleData(data);
            buildAllowedPagesArray(data);
            return data;
        }));
    }

    const checkIsAdmin = async (role) => {
        let roleToCheck = roleData;
        if (!!role) roleToCheck = role;
        if (!roleToCheck) await getRoleData();
        const adminPermissions = Object.keys(roleToCheck?.adminPermissions).filter(p => {
            if (roleToCheck.adminPermissions[p].canCreate)
                return p;
        })
        if (adminPermissions.length > 0)
            return true;
        else return false
    }
    const buildAllowedPagesArray = (role) => {
        let ary = [];
        Object.keys(role?.adminPermissions).forEach((key) => {
            if (role?.adminPermissions[key]?.canCreate) {
                switch (key) {
                    case 'boardPermissions':
                        ary.push('/admin/boards');
                        break;
                    case 'magnetGroupPermissions':
                        ary.push('/admin/magnigroups');
                        break;
                    case 'magnetPermissions':
                        ary.push('/admin/magnets');
                        break;
                    case 'userPermissions':
                        ary.push('/admin/users');
                        break;
                    case 'rolePermissions':
                        ary.push('/admin/roles');
                        break;
                    case 'archivePermissions':
                        ary.push('/admin/archives');
                        break;
                }
            }
        });
        setAllowedPages(ary);
    }
    const checkPagePermission = (page) => {

        switch (page) {
            case '/admin':
                return checkIsAdmin(undefined);
            case '/admin/boards':
                return roleData?.adminPermissions?.boardPermissions?.canCreate;
            case '/admin/magnigroups':
                return roleData?.adminPermissions?.magnetGroupPermissions?.canCreate;
            case '/admin/magnets':
                return roleData?.adminPermissions?.magnetPermissions?.canCreate;
            case '/admin/users':
                return roleData?.adminPermissions?.userPermissions?.canCreate;
            case '/admin/roles':
                return roleData?.adminPermissions?.rolePermissions?.canCreate;
            case '/admin/archives':
                return roleData?.adminPermissions?.archivePermissions?.canCreate;
            default:
                return false;
        }
    }

    const refreshUserData = async () => {
        getUser();
        getRoleData();
    }

    return (
        <AuthContext.Provider value={{ allowedPages, loading, token, createAuth, getRoleData, getToken, checkIsAdmin, getUser, getMe, logout, roleData, refreshUserData }}>
            {children}
        </AuthContext.Provider>
    )
}

export default AuthContext;
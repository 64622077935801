import { Container, Center, Paper, Stack, TextInput, PasswordInput, Group, Button, Image } from '@mantine/core';
import { useEffect, useRef, useState } from "react"
import useAuth from "../contexts/contextHooks/useAuth";
import { useNavigate } from 'react-router-dom';
import { API_URL } from '../exports/fetch/GlobalFetchSettings';
import logo from '../images/mb-full-trans.png';
import { AuthStore } from '../stores/authStore';
import { observer } from 'mobx-react';
import { LoginRequest } from '../exports/fetch/AuthFetch';
import { PluginStore } from '../Components/AdminConsole/Plugins/PluginStore';

function LoginForm() {
  const [loading, setLoading] = useState(false);
  const { createAuth, checkIsAdmin } = useAuth();
  const navigate = useNavigate();
  const userRef = useRef();
  const errRef = useRef();

  const [user, setUser] = useState('');
  const [pwd, setPwd] = useState('');
  const [errMsg, setErrMsg] = useState('');

  useEffect(() => {
    userRef.current.focus();
  }, [])

  useEffect(() => {
    setErrMsg('');
  }, [user, pwd])

  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    try {
      const response = await LoginRequest(user.toLowerCase(), pwd);
      if (!response.ok) {
        if (response?.status === 400) {
          setErrMsg('Username and Password are required.');
        }
        else if (response?.status === 401) {
          setErrMsg('Incorrect Username or Password');
        }
        throw new Error(response?.status);
      }
      const data = await response.json();

      let username = data.user.username;
      let accessToken = data.accessToken;
      let role = data.user.role;

      AuthStore.setAuth(username, role, accessToken);
      setUser('');
      setPwd('');
      setLoading(false);

      if (AuthStore.isAdmin) {
        if (data?.user?.role?.adminPermissions?.boardPermissions?.canCreate) navigate('/admin/boards')
        else if (data?.user?.role?.adminPermissions?.magnetGroupPermissions?.canCreate) navigate('/admin/magnigroups')
        else if (data?.user?.role?.adminPermissions?.magnetPermissions?.canCreate) navigate('/admin/magnets')
        else if (data?.user?.role?.adminPermissions?.userPermissions?.canCreate) navigate('/admin/users')
        else if (data?.user?.role?.adminPermissions?.rolePermissions?.canCreate) navigate('/admin/roles')
        else if (data?.user?.role?.adminPermissions?.archivePermissions?.canCreate) navigate('/admin/archives')
        else navigate('/admin');
      }
      else {
        navigate('/boards');
      }
    }
    catch (err) {
      console.error(err);
      if (!err?.response && parseInt(err.message) !== 401 && parseInt(err.message) !== 402) setErrMsg('No Server Response');
      errRef.current.focus();
    }
    finally {
      setLoading(false);
    }
  }

  return (
    <Container size="xl" px={0}>
      <Center style={{ paddingTop: "10%" }}>
        <Paper shadow="xl" p={40}>
          <Stack>
            <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive" style={{ color: 'red' }}>
              {errMsg}
            </p>

            <Center>
              <Image width={70} height={70} disabled src={logo} alt="Logo" />
            </Center>

            <form onSubmit={handleSubmit}>
              <TextInput size="md"
                type='text'
                id='username'
                ref={userRef}
                autoComplete='off'
                onChange={(e) => setUser(e.target.value)}
                value={user} //guy said this clears form upon submission
                required
                label="Username"
                placeholder="username"
              />
              <PasswordInput
                size="md"
                id='password'
                onChange={(e) => setPwd(e.target.value)}
                value={pwd}
                required
                placeholder="password"
                label="Password"
              />
              <Group position="right" mt="md">
                <Button loading={loading} type="submit">Submit</Button>
              </Group>
            </form>
          </Stack>
        </Paper>
      </Center>
    </Container>
  )
}
export default observer(LoginForm);
import { Button, Center, useMantineTheme } from '@mantine/core'
import React from 'react'

const ArchiveMagnet = (props) => {
    const theme = useMantineTheme();

    function LightenDarkenColor(col,amt) {
        var usePound = true;
        if ( col[0] == "#" ) {
            col = col.slice(1);
            usePound = true;
        }

        var num = parseInt(col,16);

        var r = (num >> 16) + amt;

        if ( r > 255 ) r = 255;
        else if  (r < 0) r = 0;

        var b = ((num >> 8) & 0x00FF) + amt;

        if ( b > 255 ) b = 255;
        else if  (b < 0) b = 0;

        var g = (num & 0x0000FF) + amt;

        if ( g > 255 ) g = 255;
        else if  ( g < 0 ) g = 0;
        var str = "000000" + (g | (b << 8) | (r << 16)).toString(16)
        return (usePound?"#":"") + str.substr(str.length-6);
    }

    const magnetStyle = {
        
            height: '100%',
            width: '100%',
            margin: 0,
            display:'flex',
            padding:'0',
            background: theme.fn.radialGradient(props.data.magnetColor, LightenDarkenColor(props.data.magnetColor.substr(1), -20)),
            justifyContent: 'center',
            borderRadius: '5px',
            boxShadow: '1px 1px 0px #F4AAB9',
            fontSize: '.7rem',
            color: props.data.textColor,
            fontWeight: 'bold',
    }

    return (
        <>
        <div className=''
            style={magnetStyle}
            ><Center>{props.data.magnetName} </Center>
        </div>
    </>
    )
}

export default ArchiveMagnet

import { LoadingOverlay, Title } from "@mantine/core";
import React, { useEffect, useState } from "react";
import { GetADPWorkerByAOID, GetADPWorkersPlugin } from "../../../../exports/fetch/PluginFetch";
import { TableSort } from "../../../Utilities/TableSort";

export const ADPFormWorkerTable = ({ setADPWorker, setShowADPWorkerForm }: any) => {
    const [ADPWorkers, setADPWorkers] = useState<any>([]);
    const [loading, setLoading] = useState(false);

    const handleClickItem = async (id: any) => {
        setLoading(true);
        const workerData = await GetADPWorkerByAOID(id);
        if (workerData) {
            setADPWorker(workerData);
            setShowADPWorkerForm(true);
        }
        setLoading(false);
    };

    const getWorkers = () => {
        GetADPWorkersPlugin("1000").then(data => {
            setADPWorkers(data.workers);
        });
    };

    useEffect(() => {
        getWorkers();
    }, []);
    const componentButton = () => { return (<></>) };

    return (
        <>
            <LoadingOverlay visible={loading} />
            <Title order={3} style={{ textAlign: 'center', paddingBottom: '10px' }}>ADP Worker List</Title>
            {ADPWorkers ? (<TableSort
                initialSortBy='Employee'
                postsPerPage={8}
                tableLayout='auto'
                componentButton={componentButton}
                handleClickItem={(id: number) => { handleClickItem(id) }}
                data={ADPWorkers?.length > 0 && ADPWorkers.map((worker: any) => {
                    return {
                        id: String(worker?.associateOID),
                        'Worker ID': String(worker?.workerID),
                        "Employee": worker?.legalName?.formattedName,
                        "Department": worker?.department?.shortName ? worker?.department?.shortName : ""
                    }
                }).filter((item: any) => item.id !== 'undefined')
                }
            />) : ""}
        </>
    )
};
import { showNotification } from "@mantine/notifications";
import { createContext, useState, useEffect, useRef } from "react";
import { GetMagnetGroups, PostMagnetGroup, PutMagnetGroup, DeleteMagnetGroup } from "../exports/fetch/MagnetGroupFetch";
import useAuth from "./contextHooks/useAuth";

const MagnetGroupContext = createContext({});

export const MagnetGroupProvider = ({ children }) => {
    const [magnetGroupList, setMagnetGroupList] = useState([]);
    const { getToken } = useAuth();

    const [loading, setLoading] = useState(true);
    const refreshInterval = useRef(null);

    const getMagnetGroups = async () => {
        const data = await GetMagnetGroups();
        setMagnetGroupList(data);
        setLoading(false);
    }

    const createMagnetGroup = (group) => {
        PostMagnetGroup(group)
            .then((data) => {
                setMagnetGroupList(prevList => [...prevList, data])
                showNotification({
                    title: 'Create',
                    message: 'MagniGroup ' + group.name + ' was created'
                });
            });
    }

    const updateMagnetGroup = (group) => {
        PutMagnetGroup(group)
            .then((data) => {
                setMagnetGroupList(prevList => prevList.map((g) => {
                    return g.id === group.id ? data : g;
                }))
                showNotification({
                    title: 'Update',
                    message: 'MagniGroup ' + group.name + ' was updated'
                });
            })
    }

    const deleteMagnetGroup = (group) => {
        DeleteMagnetGroup(group.id)
            .then(() => {
                setMagnetGroupList(prevList => prevList.filter(g => {
                    return g.id !== group.id
                }));
                showNotification({
                    title: 'Delete',
                    message: 'MagniGroup ' + group.name + ' was deleted'
                });
            });
    }

    const stopMagnetGroupListRefresh = () => {
        clearInterval(refreshInterval.current);
    }

    const startMagnetGroupListRefresh = () => {
        setLoading(true);
        getMagnetGroups();
        refreshInterval.current = setInterval(() => {
            getMagnetGroups();
        }, 10000);
    }
    const clearMagnetGroupList = () => {
        setMagnetGroupList([]);
    }

    useEffect(() => {
        return () => clearInterval(refreshInterval.current);
    }, []);

    return (
        <MagnetGroupContext.Provider value={{
            loading,
            magnetGroupList,
            setMagnetGroupList,
            getMagnetGroups,
            createMagnetGroup,
            updateMagnetGroup,
            deleteMagnetGroup,
            clearMagnetGroupList,
            stopMagnetGroupListRefresh,
            startMagnetGroupListRefresh
        }}>
            {children}
        </MagnetGroupContext.Provider>
    );
}

export default MagnetGroupContext;